import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects"
import { SavingType, SavingTypes } from "../types/savingTypes"
import {
  BoneLossFormFull,
  Comment,
  INote,
  ServerDataTypes,
} from "../types/serverDataTypes"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import { ResultStatus, UserChange } from "../types/dataStructureTypes"
import teethTypes from "library/common/types/teethTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { adjustmentTypes } from "library/common/types/adjustmentTypes"
import { UserTypes } from "../types/userTypes"
import * as imageControlsSelectors from "library/common/selectors/imageControls"
import * as routeSelectors from "library/common/selectors/routes"
import * as savingDataActions from "library/common/actions/saving"
import { history } from "core/store/configureStore"

import {
  requestSendChanges,
  requestSendEvent,
  IRequestSendReport,
  IImageManipulation,
} from "library/services/uploadApi"

import {
  getAddedComments,
  getGeneralComment,
  getAllUserChanges,
  getAllRemovedTeeth,
  getAllAddedTeeth,
  getMovedTeeth,
  getAllAdditions,
  getImageMeta,
  getBoneLossFormValues,
  getIsImageHorizontallyFlipped,
  getIsOwner,
  getBrightnessSnapped,
  getContrastSnapped,
  getSaturationSnapped,
  getNotes,
} from "library/common/selectors/serverData"
import { getCariesPro, getOpenDateMs, getBonelossPro } from "../selectors/image"
import { refreshPatientFile, resetOpenDataMsSaga } from "./imageSaga"
import { IMeta } from "../types/serverDataTypes"
import { notifyWebkitSave } from "library/utilities/integration"
import {
  setPatientFileBreadcrumb,
  setReportBreadcrumb,
} from "../actions/breadcrumbs"
import { closeModal } from "../actions/modal"
import { getOpenedModal } from "../selectors/modals"
import { Modals } from "../reducers/modalsReducer"
import { loadImage } from "../actions/image"
import { getImageId } from "../selectors/entities"
import { isDashboard } from "library/utilities/urls"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import { getActivePatientListItem } from "../selectors/patient"
import { IActivePatientListItem } from "../types/patientTypes"
import { saveImageMeta } from "../actions/serverData"

function* requestSendChangesSaga({
  payload: savingData,
}: ReturnType<typeof savingDataActions.requestSendChanges>) {
  const generalComment: string = yield select(getGeneralComment)
  const addedComments: Comment[] = yield select(getAddedComments)

  const additions: AnnotationOnTooth[] = yield select(getAllAdditions)

  const changes: UserChange[] = yield select(getAllUserChanges)

  const removedTeeth: Tooth[] = yield select(getAllRemovedTeeth)
  const addedTeeth: Tooth[] = yield select(getAllAddedTeeth)
  const movedTeeth: Record<string, number> = yield select(getMovedTeeth)

  const resultId: string = yield select(routeSelectors.getRouteImageId)

  const cariesPro: boolean | undefined = yield select(getCariesPro)
  const bonelossPro: boolean | null = yield select(getBonelossPro)
  const openedModal: Modals | null = yield select(getOpenedModal)

  const isImageHorizontallyFlipped: boolean = yield select(
    getIsImageHorizontallyFlipped
  )

  const boneLoss: BoneLossFormFull = yield select(getBoneLossFormValues)

  const meta: IMeta = yield select(getImageMeta)
  const isOwner: boolean = yield select(getIsOwner)
  const notes: INote[] = yield select(getNotes)

  yield put(savingDataActions.setSavingResultStatus(ResultStatus.loading))

  const metaData = {
    meta: {
      ...meta,
      isImageHorizontallyFlipped,
    },
  }
  const data: IRequestSendReport =
    savingData.savingChanges === SavingType.IMAGE_META_DATA
      ? {
          ...metaData,
          resultId,
        }
      : {
          ...metaData,
          resultId,
          generalComment,
          addedComments,
          additions,
          notes,
          addedTeeth,
          changes,
          removedTeeth,
          movedTeeth,
          cariesPro,
          bonelossPro: bonelossPro ?? undefined, // remove it from the request if the value is null
          forms:
            bonelossPro !== null
              ? {
                  boneLoss: boneLoss,
                }
              : undefined,
        }
  try {
    if (isOwner) {
      const params: ContextQuery = yield select(getContextQueryParams)
      yield call(requestSendChanges, data, params)
      yield call(refreshPatientFile)
    }

    // Only when assigning an image to a patient do we reload the image to get latest changes
    if (
      savingData.savingChanges === SavingType.IMAGE_META_DATA &&
      isDashboard(history.location)
    ) {
      const imageId: string = yield select(getImageId)
      yield put(loadImage(imageId))
    }
    if (savingData.savingChanges !== SavingType.IMAGE_META_DATA) {
      yield put(
        savingDataActions.requestSendChangesComplete({
          success: true,
          id: resultId,
          savedWithButton: savingData.savedWithButton || false,
        })
      )
    } else {
      // TODO: fetch new patient id to keep breadcrumb
      yield put(setPatientFileBreadcrumb(""))
    }
    yield put(savingDataActions.setSavingResultStatus(ResultStatus.none))
    if (openedModal) {
      yield put(closeModal())
    }
  } catch (error) {
    yield put(savingDataActions.setSavingResultStatus(ResultStatus.error))
    yield put(
      savingDataActions.requestSendChangesComplete({
        success: false,
        id: resultId,
        savedWithButton: false,
      })
    )
    console.error(error)
    // TODO (Tim): Show user the error screen.
  }
}

function* requestSendEventSaga({
  payload: button,
}: ReturnType<typeof savingDataActions.requestSendEvent>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const openDateMs: number = yield select(getOpenDateMs)
  const editorOpenMs = openDateMs ? Date.now() - openDateMs : undefined
  const imageManipulation: IImageManipulation = {
    brightness: yield select(getBrightnessSnapped),
    contrast: yield select(getContrastSnapped),
    saturation: yield select(getSaturationSnapped),
  }
  const usedFullscreen: boolean = yield select(
    imageControlsSelectors.getUsedFullscreen
  )
  const activePatientListItem: IActivePatientListItem | null = yield select(
    getActivePatientListItem
  )

  try {
    yield call(requestSendEvent, {
      action: "close",
      resultId: activePatientListItem?.id || resultId,
      editorOpenMs,
      button,
      imageManipulation,
      usedFullscreen,
    })
  } catch (error) {
    console.log(error)
  }
}

function* requestSendChangesCompleteSaga({
  payload,
}: ReturnType<typeof savingDataActions.requestSendChangesComplete>) {
  if (payload.success && payload.savedWithButton) {
    notifyWebkitSave(payload.id)
  }
}

function* allowUserToSaveSaga() {
  if (isDashboard(history.location)) {
    yield put(savingDataActions.setDataIsChanged(true))
  }

  // Reset the possibility to navigate to report via breadcrumbs after changes.
  yield put(setReportBreadcrumb(""))
}

function* setOrthoConditionDismissedSaga() {
  const imageMeta: IMeta = yield select(getImageMeta)
  if (
    imageMeta.warnings?.orthoCondition &&
    !imageMeta.orthoConditionDismissed
  ) {
    yield put(saveImageMeta({ ...imageMeta, orthoConditionDismissed: true }))
    yield put(
      savingDataActions.requestSendChanges({
        savingChanges: SavingType.IMAGE_META_DATA,
      })
    )
  }
}

export default function* savingDataSaga() {
  yield takeLatest(SavingTypes.REQUEST_SEND_CHANGES, requestSendChangesSaga)
  yield takeLatest(
    SavingTypes.REQUEST_SEND_CHANGES_COMPLETE,
    requestSendChangesCompleteSaga
  )

  const { CREATE_TOOTH, DELETE_TOOTH } = teethTypes
  const { TOGGLE_CARIES_PRO, TOGGLE_BONELOSS_PRO } = UserTypes
  const { TOGGLE_FLIP_IMAGE } = ServerDataTypes

  yield takeEvery(
    [
      CREATE_TOOTH,
      DELETE_TOOTH,
      adjustmentTypes.TOGGLE_ANNOTATION_ON_TOOTH,
      TOGGLE_CARIES_PRO,
      TOGGLE_FLIP_IMAGE,
      adjustmentTypes.MOVE_ANNOTATION_TO,
      // For cariesPRO annotations
      ServerDataTypes.ADD_CARIES_ADDITIONS,
      ServerDataTypes.DELETE_USER_ADDITION_BY_ID,
      ServerDataTypes.TOGGLE_ANNOTATION,
      ServerDataTypes.CHANGE_ANNOTATION,
      // For system tests
      ServerDataTypes.TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED,
      // For boneloss
      TOGGLE_BONELOSS_PRO,
      ServerDataTypes.SET_TOOTH_BONELOSS,
      ServerDataTypes.SET_TEETH_LOSS,
      ServerDataTypes.SET_BONE_LOSS_CATEGORY,
      ServerDataTypes.SET_BONE_LOSS_INDEX,
      ServerDataTypes.SET_AGE,
      ServerDataTypes.SET_DIABETES,
      ServerDataTypes.SET_SMOKING,
      ServerDataTypes.SET_EXTEND_OR_DISTRIBUTION,
      ServerDataTypes.SET_COMPLEXITY,
    ],
    allowUserToSaveSaga
  ) // one more case is when HSM changes are accepted or rejected but this is found in hsmControlButtons.
  yield takeLatest(SavingTypes.REQUEST_SEND_EVENT, requestSendEventSaga)
  yield takeLatest(SavingTypes.REQUEST_SEND_EVENT, resetOpenDataMsSaga)
  yield takeLatest(
    SavingTypes.SET_ORTHO_CONDITION_DISMISSED,
    setOrthoConditionDismissedSaga
  )
}
