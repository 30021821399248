export enum UploadTypes {
  UPLOAD_ACTION_NEW_IMAGE = "@@UPLOAD/UPLOAD_ACTION_NEW_IMAGE",
  UPLOAD_ACTION_PRELOADER_IMAGE = "@@UPLOAD/UPLOAD_ACTION_PRELOADER_IMAGE",
  UPLOAD_ACTION_ERROR = "@@UPLOAD/UPLOAD_ACTION_ERROR",
  UPLOAD_ACTION_DATA_IMAGE = "@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE",
  UPLOAD_ACTION_DATA_IMAGE_SUCCESS = "@@UPLOAD/UPLOAD_ACTION_DATA_IMAGE_SUCCESS",
  UPLOAD_ACTION_WITH_DATA = "@@UPLOAD/UPLOAD_ACTION_WITH_DATA",
  UPLOAD_ACTION_SUCCESS = "@@UPLOAD/UPLOAD_ACTION_SUCCESS",
  UPLOAD_ACTION_SAVE_USER_META_DATA = "@@UPLOAD/UPLOAD_ACTION_SAVE_USER_META_DATA",
  UPLOAD_ACTION_SEND_REPORT_PROBLEM = "@@UPLOAD/UPLOAD_ACTION_SEND_REPORT_PROBLEM",
  UPLOAD_ACTION_SET_REPORT_PROBLEM_ID = "@@UPLOAD/UPLOAD_ACTION_SET_REPORT_PROBLEM_ID",
  UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT = "@@UPLOAD/UPLOAD_ACTION_SET_SHOULD_TAKE_SCREENSHOT",
  UPLOAD_ACTION_SET_SCREENSHOT = "@@UPLOAD/UPLOAD_ACTION_SET_SCREENSHOT",
  SET_USER_INFO = "@@UPLOAD/SET_USER_INFO",
  UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR = "@@UPLOAD/UPLOAD_ACTION_SET_IMAGE_SIZE_ERROR",
  UPLOAD_ACTION_SET_DEMO_MODE = "@@UPLOAD/UPLOAD_ACTION_SET_DEMO_MODE",
}
