import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/patient"
import {
  FilterState,
  ActivePatientResult,
  PatientListResult,
  PatientMatch,
  PatientListSorting,
} from "../types/patientTypes"
import { filterSections } from "pages/Patients/PatientList/filterSections"
import { ResultStatus } from "../types/dataStructureTypes"

type PatientState = Readonly<{
  patientListResult: PatientListResult
  activePatientResult: ActivePatientResult
  filterValues: FilterState[]
  isPatientMatchingMode: boolean
  patientMatch: PatientMatch | null
  patientListSorting: PatientListSorting | null
}>
export const initialFilters = filterSections.map((s) => ({
  section: s.heading.value,
  selected: [],
  match: s.match,
}))

const initialState: PatientState = {
  patientListResult: {
    resultStatus: ResultStatus.none,
    patientList: [],
  },
  activePatientResult: {
    resultStatus: ResultStatus.none,
    images: null,
    patient: null,
    links: [],
    radiographSets: [],
    activeRadiographSet: null,
    activePatientListItem: null,
    isAccordionOpen: false,
  },
  filterValues: initialFilters,
  isPatientMatchingMode: false,
  patientMatch: null,
  patientListSorting: { key: "imageDate", sortDirection: "DESC" },
}

type PatientActions = ActionType<typeof actions>

export default (state = initialState, action: PatientActions): PatientState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return {
        ...initialState,
        patientMatch: state.patientMatch,
        isPatientMatchingMode: state.isPatientMatchingMode,
        patientListSorting: state.patientListSorting,
        activePatientResult: state.activePatientResult,
      }
    }
    case getType(actions.setPatientListResult): {
      return {
        ...state,
        patientListResult: action.payload,
      }
    }
    case getType(actions.setPatientListResultStatus): {
      return {
        ...state,
        patientListResult: {
          ...state.patientListResult,
          resultStatus: action.payload,
        },
      }
    }

    case getType(actions.setActivePatientResult): {
      return {
        ...state,
        activePatientResult: action.payload,
      }
    }

    case getType(actions.clearActivePatientResult): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: ResultStatus.none,
          images: null,
          patient: null,
          links: [],
          radiographSets: [],
          activeRadiographSet: null,
          activePatientListItem: null,
        },
      }
    }

    case getType(actions.setActivePatientResultStatus): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          resultStatus: action.payload,
        },
      }
    }

    case getType(actions.setConfirmedAnnotations): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.map((image) =>
              image.resultId ===
              state.activePatientResult.activePatientListItem?.id
                ? {
                    ...image,
                    confirmedAnnotations: action.payload,
                  }
                : image
            ) || [],
        },
      }
    }
    case getType(actions.setActivePatientListItem): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activePatientListItem: action.payload,
        },
      }
    }

    case getType(actions.toggleAccordion): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          isAccordionOpen: action.payload,
        },
      }
    }

    case getType(actions.deleteActivePatientXray): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          images:
            state.activePatientResult.images?.filter(
              (a) => a.resultId !== action.payload
            ) || [],
        },
      }
    }
    case getType(actions.setFilterValues): {
      return {
        ...state,
        filterValues: action.payload,
      }
    }
    case getType(actions.setIsPatientMatchingMode): {
      return {
        ...state,
        isPatientMatchingMode: action.payload,
      }
    }
    case getType(actions.setPatientMatch): {
      return {
        ...state,
        patientMatch: action.payload,
      }
    }
    case getType(actions.setPatientListSorting): {
      return {
        ...state,
        patientListSorting: action.payload,
      }
    }
    case getType(actions.setActiveRadiographSet): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          activeRadiographSet: action.payload,
        },
      }
    }
    case getType(actions.setPatientMetadata): {
      return {
        ...state,
        activePatientResult: {
          ...state.activePatientResult,
          ...action.payload,
        },
      }
    }

    default:
      return state
  }
}
