import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/image"
import { DEFAULT_THEME } from "library/utilities/constants"
import { Theme } from "../actions/user"
import { ResultStatus } from "../types/dataStructureTypes"

type EntitiesState = Readonly<{
  isProcessed: boolean
  openDateMs: number
  pdfReportData: string
  showImmediately: boolean
  firstPage: string
  textAnnotations: string
  acceptMissingMetadata: boolean | null
  imageResultStatus: ResultStatus
  shownRadiographAnnotations: string // Id of the radiograph that has annotations shown
}>

const initialState: EntitiesState = {
  isProcessed: false,
  openDateMs: Date.now(),
  pdfReportData: "",
  showImmediately: DEFAULT_THEME === Theme.carestream,
  firstPage: "",
  textAnnotations: "",
  acceptMissingMetadata: null,
  imageResultStatus: ResultStatus.none,
  shownRadiographAnnotations: "",
}

type EntitiesActions = ActionType<typeof actions>

export default (
  state = initialState,
  action: EntitiesActions
): EntitiesState => {
  switch (action.type) {
    case getType(actions.loadImageSuccess):
      return { ...state, openDateMs: Date.now() }

    case getType(actions.loadAnnotationsSuccess):
      if (state.showImmediately) {
        return { ...state, isProcessed: true }
      }
      return { ...state, isProcessed: true }

    case getType(actions.setShownRadiographAnnotations):
      return { ...state, shownRadiographAnnotations: action.payload }

    case getType(actions.setInitialState):
      return {
        ...initialState,
        showImmediately: state.showImmediately,
        acceptMissingMetadata: state.acceptMissingMetadata,
        shownRadiographAnnotations: state.shownRadiographAnnotations,
      }

    case getType(actions.setAsNotProcessed):
      return { ...state, isProcessed: false }

    case getType(actions.updateOpenDateMs):
      return { ...state, openDateMs: Date.now() }

    case getType(actions.setAcceptMissingMetadata):
      return { ...state, acceptMissingMetadata: action.payload }

    case getType(actions.loadPdfReportSuccess):
      return {
        ...state,
        ...action.payload,
      }

    case getType(actions.setShowImmediately):
      return {
        ...state,
        showImmediately: action.payload,
      }
    case getType(actions.setImageResultStatus):
      return {
        ...state,
        imageResultStatus: action.payload,
      }

    default:
      return state
  }
}
