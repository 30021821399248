import {
  CariesDepth,
  BonelossAnnotation,
  CroppedTeethDirection,
} from "./adjustmentTypes"

export type UserActionType = "rejected" | "accepted" | "moved" | "changed"

export interface UserChange {
  annotationId: number
  action: UserActionType
  isHSM?: boolean
  newTooth?: number
  // Caries Pro:
  location?: string
  depth?: CariesDepth
  rejectedDetections?: number[]
}

export type BBoxPosition = Readonly<{
  x1: number
  y1: number
  x2: number
  y2: number
}>

export type Detection = Readonly<{
  id: number
  mask: string
  subtype: string
  toothName: number
  // Caries Pro:
  location?: string
  depth?: CariesDepth
  center_mass_x?: number // Only for PERI
  center_mass_y?: number // Only for PERI
  replacing?: number // HSM on PERI: blob replaces another blob ID
  fusion?: number[]
  bbox?: BBoxPosition
}>

export type ICroppedTeeth = {
  [key: number]: CroppedTeethDirection
}

export type DetectionInfo = Readonly<{
  visible: boolean
  name: string
  detection: Detection
}>

export type PbRoi = Readonly<{
  height: number
  width: number
  x: number
  y: number
}>

export type Boneloss = Readonly<{
  annotations: BonelossAnnotation[]
  mask?: string
  fake?: boolean
  pb_roi?: PbRoi
}>

export type ToothSegment = Readonly<{
  id: number
  mask: string
  toothName: number
  confidence: number
  rotate: number
  width: number
  height: number
  x: number
  y: number
}>

export type DetectionString = Readonly<{
  string: string
  hidden: boolean
  id?: number
}>

export type HistoricalResult = Readonly<{
  id: number
  aiVersion: string
  current: string[]
  created: string
  edited: boolean
}>

export type NerveCanal = Readonly<{
  id: number
  subtype: string
  mask: string
  toothCoordinates: ToothCoordinate[]
}>

export type ToothCoordinate = Readonly<{
  toothName: number
  radius: number
  center: number[]
}>

export enum ResultStatus {
  success = "success",
  error = "error",
  loading = "loading",
  none = "none",
}
