import React from "react"

export const ArrangeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7183_7162)">
      <mask
        id="mask0_7183_7162"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 0V20H0V0H20Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7183_7162)">
        <mask id="path-2-inside-1_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 11.8334H8.5L8.5 17.3334H11.5V11.8334ZM8.5 10.8334C7.94772 10.8334 7.5 11.2811 7.5 11.8334V17.3334C7.5 17.8857 7.94772 18.3334 8.5 18.3334H11.5C12.0523 18.3334 12.5 17.8857 12.5 17.3334V11.8334C12.5 11.2811 12.0523 10.8334 11.5 10.8334H8.5Z"
          />
        </mask>
        <path
          d="M8.5 11.8334V11.0834H7.75V11.8334H8.5ZM11.5 11.8334H12.25V11.0834H11.5V11.8334ZM8.5 17.3334H7.75L7.75 18.0834H8.5V17.3334ZM11.5 17.3334V18.0834H12.25V17.3334H11.5ZM8.5 12.5834H11.5V11.0834H8.5V12.5834ZM9.25 17.3334V11.8334H7.75L7.75 17.3334H9.25ZM11.5 16.5834H8.5V18.0834H11.5V16.5834ZM10.75 11.8334V17.3334H12.25V11.8334H10.75ZM8.25 11.8334C8.25 11.6953 8.36193 11.5834 8.5 11.5834V10.0834C7.5335 10.0834 6.75 10.8669 6.75 11.8334H8.25ZM8.25 17.3334V11.8334H6.75V17.3334H8.25ZM8.5 17.5834C8.36193 17.5834 8.25 17.4714 8.25 17.3334H6.75C6.75 18.2999 7.5335 19.0834 8.5 19.0834V17.5834ZM11.5 17.5834H8.5V19.0834H11.5V17.5834ZM11.75 17.3334C11.75 17.4714 11.6381 17.5834 11.5 17.5834V19.0834C12.4665 19.0834 13.25 18.2999 13.25 17.3334H11.75ZM11.75 11.8334V17.3334H13.25V11.8334H11.75ZM11.5 11.5834C11.6381 11.5834 11.75 11.6953 11.75 11.8334H13.25C13.25 10.8669 12.4665 10.0834 11.5 10.0834V11.5834ZM8.5 11.5834H11.5V10.0834H8.5V11.5834Z"
          fill="currentColor"
          mask="url(#path-2-inside-1_7183_7162)"
        />
        <mask id="path-4-inside-2_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9997 11.8334H14.333V11.8334L14.333 15.6667H18.9997L18.9997 11.8334ZM14.333 10.8334C13.7807 10.8334 13.333 11.2811 13.333 11.8334V15.6667C13.333 16.219 13.7807 16.6667 14.333 16.6667H18.9997C19.552 16.6667 19.9997 16.219 19.9997 15.6667V11.8334C19.9997 11.2811 19.552 10.8334 18.9997 10.8334H14.333Z"
          />
        </mask>
        <path
          d="M14.333 11.8334V11.0834H13.583V11.8334H14.333ZM18.9997 11.8334L19.7497 11.8334V11.0834H18.9997V11.8334ZM14.333 11.8334L15.083 11.8334V11.8334H14.333ZM14.333 15.6667H13.583L13.583 16.4167H14.333V15.6667ZM18.9997 15.6667V16.4167H19.7497V15.6667H18.9997ZM14.333 12.5834H18.9997V11.0834H14.333V12.5834ZM15.083 11.8334V11.8334H13.583V11.8334H15.083ZM15.083 15.6667L15.083 11.8334L13.583 11.8334L13.583 15.6667H15.083ZM18.9997 14.9167H14.333V16.4167H18.9997V14.9167ZM18.2497 11.8334L18.2497 15.6667H19.7497L19.7497 11.8334L18.2497 11.8334ZM14.083 11.8334C14.083 11.6953 14.1949 11.5834 14.333 11.5834V10.0834C13.3665 10.0834 12.583 10.8669 12.583 11.8334H14.083ZM14.083 15.6667V11.8334H12.583V15.6667H14.083ZM14.333 15.9167C14.1949 15.9167 14.083 15.8048 14.083 15.6667H12.583C12.583 16.6332 13.3665 17.4167 14.333 17.4167V15.9167ZM18.9997 15.9167H14.333V17.4167H18.9997V15.9167ZM19.2497 15.6667C19.2497 15.8048 19.1377 15.9167 18.9997 15.9167V17.4167C19.9662 17.4167 20.7497 16.6332 20.7497 15.6667H19.2497ZM19.2497 11.8334V15.6667H20.7497V11.8334H19.2497ZM18.9997 11.5834C19.1377 11.5834 19.2497 11.6953 19.2497 11.8334H20.7497C20.7497 10.8669 19.9662 10.0834 18.9997 10.0834V11.5834ZM14.333 11.5834H18.9997V10.0834H14.333V11.5834Z"
          fill="currentColor"
          mask="url(#path-4-inside-2_7183_7162)"
        />
        <mask id="path-6-inside-3_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.66667 11.8334H1V11.8334L0.999999 15.6667H5.66667L5.66667 11.8334ZM1 10.8334C0.447715 10.8334 0 11.2811 0 11.8334V15.6667C0 16.219 0.447714 16.6667 0.999999 16.6667H5.66667C6.21895 16.6667 6.66667 16.219 6.66667 15.6667V11.8334C6.66667 11.2811 6.21895 10.8334 5.66667 10.8334H1Z"
          />
        </mask>
        <path
          d="M1 11.8334V11.0834H0.25V11.8334H1ZM5.66667 11.8334L6.41667 11.8334V11.0834H5.66667V11.8334ZM1 11.8334L1.75 11.8334V11.8334H1ZM0.999999 15.6667H0.249999L0.249999 16.4167H0.999999V15.6667ZM5.66667 15.6667V16.4167H6.41667V15.6667H5.66667ZM1 12.5834H5.66667V11.0834H1V12.5834ZM1.75 11.8334V11.8334H0.25V11.8334H1.75ZM1.75 15.6667L1.75 11.8334L0.25 11.8334L0.249999 15.6667H1.75ZM5.66667 14.9167H0.999999V16.4167H5.66667V14.9167ZM4.91667 11.8334L4.91667 15.6667H6.41667L6.41667 11.8334L4.91667 11.8334ZM0.75 11.8334C0.75 11.6953 0.86193 11.5834 1 11.5834V10.0834C0.0335008 10.0834 -0.75 10.8669 -0.75 11.8334H0.75ZM0.75 15.6667V11.8334H-0.75V15.6667H0.75ZM0.999999 15.9167C0.861929 15.9167 0.75 15.8048 0.75 15.6667H-0.75C-0.75 16.6332 0.0334998 17.4167 0.999999 17.4167V15.9167ZM5.66667 15.9167H0.999999V17.4167H5.66667V15.9167ZM5.91667 15.6667C5.91667 15.8048 5.80474 15.9167 5.66667 15.9167V17.4167C6.63317 17.4167 7.41667 16.6332 7.41667 15.6667H5.91667ZM5.91667 11.8334V15.6667H7.41667V11.8334H5.91667ZM5.66667 11.5834C5.80474 11.5834 5.91667 11.6953 5.91667 11.8334H7.41667C7.41667 10.8669 6.63317 10.0834 5.66667 10.0834V11.5834ZM1 11.5834H5.66667V10.0834H1V11.5834Z"
          fill="currentColor"
          mask="url(#path-6-inside-3_7183_7162)"
        />
        <mask id="path-8-inside-4_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9997 4.33337H14.333V4.33338L14.333 8.16671H18.9997L18.9997 4.33337ZM14.333 3.33337C13.7807 3.33337 13.333 3.78109 13.333 4.33338V8.16671C13.333 8.71899 13.7807 9.16671 14.333 9.16671H18.9997C19.552 9.16671 19.9997 8.71899 19.9997 8.16671V4.33337C19.9997 3.78109 19.552 3.33337 18.9997 3.33337H14.333Z"
          />
        </mask>
        <path
          d="M14.333 4.33337V3.58337H13.583V4.33337H14.333ZM18.9997 4.33337L19.7497 4.33337V3.58337H18.9997V4.33337ZM14.333 4.33338L15.083 4.33338V4.33338H14.333ZM14.333 8.16671H13.583L13.583 8.91671H14.333V8.16671ZM18.9997 8.16671V8.91671H19.7497V8.16671H18.9997ZM14.333 5.08337H18.9997V3.58337H14.333V5.08337ZM15.083 4.33338V4.33337H13.583V4.33338H15.083ZM15.083 8.16671L15.083 4.33338L13.583 4.33337L13.583 8.16671H15.083ZM18.9997 7.41671H14.333V8.91671H18.9997V7.41671ZM18.2497 4.33337L18.2497 8.16671H19.7497L19.7497 4.33337L18.2497 4.33337ZM14.083 4.33338C14.083 4.1953 14.1949 4.08337 14.333 4.08337V2.58337C13.3665 2.58337 12.583 3.36688 12.583 4.33338H14.083ZM14.083 8.16671V4.33338H12.583V8.16671H14.083ZM14.333 8.41671C14.1949 8.41671 14.083 8.30478 14.083 8.16671H12.583C12.583 9.13321 13.3665 9.91671 14.333 9.91671V8.41671ZM18.9997 8.41671H14.333V9.91671H18.9997V8.41671ZM19.2497 8.16671C19.2497 8.30478 19.1377 8.41671 18.9997 8.41671V9.91671C19.9662 9.91671 20.7497 9.1332 20.7497 8.16671H19.2497ZM19.2497 4.33337V8.16671H20.7497V4.33337H19.2497ZM18.9997 4.08337C19.1377 4.08337 19.2497 4.1953 19.2497 4.33337H20.7497C20.7497 3.36688 19.9662 2.58337 18.9997 2.58337V4.08337ZM14.333 4.08337H18.9997V2.58337H14.333V4.08337Z"
          fill="currentColor"
          mask="url(#path-8-inside-4_7183_7162)"
        />
        <mask id="path-10-inside-5_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 2.66663H8.5L8.5 8.16663H11.5V2.66663ZM8.5 1.66663C7.94772 1.66663 7.5 2.11434 7.5 2.66663V8.16663C7.5 8.71891 7.94772 9.16663 8.5 9.16663H11.5C12.0523 9.16663 12.5 8.71891 12.5 8.16663V2.66663C12.5 2.11434 12.0523 1.66663 11.5 1.66663H8.5Z"
          />
        </mask>
        <path
          d="M8.5 2.66663V1.91663H7.75V2.66663H8.5ZM11.5 2.66663H12.25V1.91663H11.5V2.66663ZM8.5 8.16663H7.75L7.75 8.91663H8.5V8.16663ZM11.5 8.16663V8.91663H12.25V8.16663H11.5ZM8.5 3.41663H11.5V1.91663H8.5V3.41663ZM9.25 8.16663V2.66663H7.75L7.75 8.16663H9.25ZM11.5 7.41663H8.5V8.91663H11.5V7.41663ZM10.75 2.66663V8.16663H12.25V2.66663H10.75ZM8.25 2.66663C8.25 2.52855 8.36193 2.41663 8.5 2.41663V0.916626C7.5335 0.916626 6.75 1.70013 6.75 2.66663H8.25ZM8.25 8.16663V2.66663H6.75V8.16663H8.25ZM8.5 8.41663C8.36193 8.41663 8.25 8.3047 8.25 8.16663H6.75C6.75 9.13312 7.5335 9.91663 8.5 9.91663V8.41663ZM11.5 8.41663H8.5V9.91663H11.5V8.41663ZM11.75 8.16663C11.75 8.3047 11.6381 8.41663 11.5 8.41663V9.91663C12.4665 9.91663 13.25 9.13312 13.25 8.16663H11.75ZM11.75 2.66663V8.16663H13.25V2.66663H11.75ZM11.5 2.41663C11.6381 2.41663 11.75 2.52855 11.75 2.66663H13.25C13.25 1.70013 12.4665 0.916626 11.5 0.916626V2.41663ZM8.5 2.41663H11.5V0.916626H8.5V2.41663Z"
          fill="currentColor"
          mask="url(#path-10-inside-5_7183_7162)"
        />
        <mask id="path-12-inside-6_7183_7162" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.66667 4.33337H1V4.33338L0.999999 8.16671H5.66667L5.66667 4.33337ZM1 3.33337C0.447715 3.33337 0 3.78109 0 4.33338V8.16671C0 8.71899 0.447714 9.16671 0.999999 9.16671H5.66667C6.21895 9.16671 6.66667 8.71899 6.66667 8.16671V4.33337C6.66667 3.78109 6.21895 3.33337 5.66667 3.33337H1Z"
          />
        </mask>
        <path
          d="M1 4.33337V3.58337H0.25V4.33337H1ZM5.66667 4.33337L6.41667 4.33337V3.58337H5.66667V4.33337ZM1 4.33338L1.75 4.33338V4.33338H1ZM0.999999 8.16671H0.249999L0.249999 8.91671H0.999999V8.16671ZM5.66667 8.16671V8.91671H6.41667V8.16671H5.66667ZM1 5.08337H5.66667V3.58337H1V5.08337ZM1.75 4.33338V4.33337H0.25V4.33338H1.75ZM1.75 8.16671L1.75 4.33338L0.25 4.33337L0.249999 8.16671H1.75ZM5.66667 7.41671H0.999999V8.91671H5.66667V7.41671ZM4.91667 4.33337L4.91667 8.16671H6.41667L6.41667 4.33337L4.91667 4.33337ZM0.75 4.33338C0.75 4.1953 0.86193 4.08337 1 4.08337V2.58337C0.0335007 2.58337 -0.75 3.36688 -0.75 4.33338H0.75ZM0.75 8.16671V4.33338H-0.75V8.16671H0.75ZM0.999999 8.41671C0.861929 8.41671 0.75 8.30478 0.75 8.16671H-0.75C-0.75 9.13321 0.0334998 9.91671 0.999999 9.91671V8.41671ZM5.66667 8.41671H0.999999V9.91671H5.66667V8.41671ZM5.91667 8.16671C5.91667 8.30478 5.80474 8.41671 5.66667 8.41671V9.91671C6.63317 9.91671 7.41667 9.1332 7.41667 8.16671H5.91667ZM5.91667 4.33337V8.16671H7.41667V4.33337H5.91667ZM5.66667 4.08337C5.80474 4.08337 5.91667 4.1953 5.91667 4.33337H7.41667C7.41667 3.36688 6.63317 2.58337 5.66667 2.58337V4.08337ZM1 4.08337H5.66667V2.58337H1V4.08337Z"
          fill="currentColor"
          mask="url(#path-12-inside-6_7183_7162)"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_7183_7162">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
