export enum ImageControlsTypes {
  SET_ZOOM = "@@IMAGE_CONTROLS/SET_ZOOM",
  SET_ZOOM_SUCCESS = "@@IMAGE_CONTROLS/SET_ZOOM_SUCCESS",
  SET_DIMENSIONS = "@@IMAGE_CONTROLS/SET_DIMENSIONS",
  SET_ZOOM_CLICK = "@@IMAGE_CONTROLS/SET_ZOOM_CLICK",
  SET_ACTIVE_FILTER = "@@IMAGE_CONTROLS/SET_ACTIVE_FILTER",
  SET_ACTIVE_FILTER_SUCCESS = "@@IMAGE_CONTROLS/SET_ACTIVE_FILTER_SUCCESS",
  REDUCER_STATE_CHANGE = "@@IMAGE_CONTROLS/REDUCER_STATE_CHANGE",
  SET_INITIAL_STATE = "@@IMAGE_CONTROLS/SET_INITIAL_STATE",
  TOGGLE_FULLSCREEN = "@@IMAGE_CONTROLS/TOGGLE_FULLSCREEN",
  SET_USED_FULLSCREEN = "@@IMAGE/SET_USED_FULLSCREEN",
  SET_IS_LARGER_ASPECT_RATIO_SCREEN = "@@IMAGE/SET_IS_LARGER_ASPECT_RATIO_SCREEN",
  SET_IS_SMALL_SCREEN = "@@IMAGE/SET_IS_SMALL_SCREEN",
  SET_SHOW_DYNAMIC_PBL = "@@SERVER_DATA/SET_SHOW_DYNAMIC_PBL",
  TOGGLE_IS_MEASUREMENT_TOOL_ACTIVE = "@@SERVER_DATA/TOGGLE_IS_MEASUREMENT_TOOL_ACTIVE",
  SET_SHOULD_RESET_ZOOM = "@@SERVER_DATA/SET_SHOULD_RESET_ZOOM",
}
