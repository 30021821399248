import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/teeth"

export interface ITeethTemplate {
  upLeft: number[]
  upRight: number[]
  downLeft: number[]
  downRight: number[]
}

type TeethState = Readonly<{
  activeTooth: number | null
  detectedTeeth: Array<{ toothName: number }> | null
  teethTemplate: ITeethTemplate
  teethTemplateReversed: ITeethTemplate
}>

// in order of display (left to right)
const teethTemplate = {
  upLeft: [18, 17, 16, 15, 14, 13, 12, 11],
  upRight: [21, 22, 23, 24, 25, 26, 27, 28],
  downLeft: [48, 47, 46, 45, 44, 43, 42, 41],
  downRight: [31, 32, 33, 34, 35, 36, 37, 38],
}

// in order of display (right to left)
const teethTemplateReversed = {
  upLeft: [28, 27, 26, 25, 24, 23, 22, 21],
  upRight: [11, 12, 13, 14, 15, 16, 17, 18],
  downLeft: [38, 37, 36, 35, 34, 33, 32, 31],
  downRight: [41, 42, 43, 44, 45, 46, 47, 48],
}

const initialState: TeethState = {
  activeTooth: null,
  detectedTeeth: null,
  teethTemplate,
  teethTemplateReversed,
}

type TeethActions = ActionType<typeof actions>

export default (state = initialState, action: TeethActions): TeethState => {
  switch (action.type) {
    case getType(actions.setActiveTooth):
      const activeTooth = action.payload

      return { ...state, activeTooth }

    case getType(actions.saveDetectedTeeth):
      const detectedTeeth = action.payload

      return { ...state, detectedTeeth }

    case getType(actions.setInitialState):
      return { ...initialState }

    default:
      return state
  }
}
