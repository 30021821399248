import React from "react"

export const REFIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M3.80951 11.2666V36.5626H44.2519V11.2666H3.80951ZM5.00951 12.4666H43.0519V35.3631H5.00951V12.4666ZM8.21999 17.5731V30.2276H10.7625V25.7398H12.2354H13.7307L13.9852 25.9348C14.4772 26.312 14.4875 26.3623 14.5074 28.4248L14.5252 30.2276H17.3865C17.4256 30.2276 17.3204 30.0894 17.2116 29.9998C17.1719 29.967 17.1162 29.9 17.0898 29.7565C17.0749 29.6759 17.0724 29.5802 17.0715 29.4256C17.0697 29.1216 17.0401 28.6758 17.0401 27.9945C17.0401 26.5431 17.0269 26.3197 16.9182 25.9273C16.7763 25.4152 16.634 25.1193 16.3056 24.6532L16.0684 24.3162L16.2877 24.0288C16.7843 23.3784 16.981 22.7835 17.0227 21.8079C17.0578 20.9899 16.982 20.5804 16.674 19.9198C16.182 18.8645 15.353 18.1413 14.19 17.7532C13.7781 17.6158 13.7165 17.6129 10.9951 17.5934L8.22024 17.5732L8.21999 17.5731ZM19.6346 17.5989V23.925V30.2274H24.0146H28.4246V27.7341H22.1301V25.1668H27.78V22.6444H22.1301V20.0845H28.4246V17.5989H19.6346ZM31.05 17.5989L31.0345 23.9245L31.0195 30.2274H33.4954V25.1668H38.58V22.6444H33.4954V20.0845H39.81V17.5989H31.05ZM11.7751 20.1174C13.2716 20.0911 13.6779 20.1661 14.1114 20.6204C14.3497 20.8701 14.5174 21.2858 14.5182 21.6296C14.5196 22.1741 14.1201 22.7952 13.6201 23.026C13.4534 23.103 13.1445 23.1276 12.1201 23.146C11.4105 23.1588 10.7626 23.1456 10.7626 23.1456V20.1352L11.7751 20.1174Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
