import React from "react"

export const NewAnalysisIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.50003H5.08501C6.05502 6.00927 7.48082 4.8719 9.14987 4.25746C10.8189 3.64302 12.6419 3.58441 14.3469 4.09037C16.052 4.59633 17.5479 5.63976 18.6117 7.06512C19.6755 8.49049 20.2501 10.2215 20.25 12H21.75C21.7521 9.99415 21.1354 8.03644 19.9841 6.39386C18.8328 4.75128 17.2029 3.50377 15.3166 2.82143C13.4304 2.1391 11.3796 2.05515 9.44384 2.58103C7.50812 3.10692 5.78171 4.21704 4.50001 5.76003V3.00003H3.00001V9.00003H9V7.50003Z"
      fill="currentColor"
    />
    <path
      d="M15 16.5H18.915C17.945 17.9908 16.5192 19.1282 14.8501 19.7426C13.1811 20.357 11.3582 20.4156 9.65308 19.9097C7.948 19.4037 6.45209 18.3603 5.38832 16.9349C4.32455 15.5096 3.74989 13.7786 3.75001 12H2.25001C2.24794 14.0059 2.8646 15.9636 4.0159 17.6062C5.1672 19.2488 6.79711 20.4963 8.68337 21.1786C10.5696 21.861 12.6204 21.9449 14.5562 21.419C16.4919 20.8931 18.2183 19.783 19.5 18.24V21H21V15H15V16.5Z"
      fill="currentColor"
    />
  </svg>
)
