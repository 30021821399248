import React from "react"

export const ReleaseIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M32.4864 28.4782V8.79004H40.5043V39.2076H7.49713V28.4782L12.0526 20.588L16.0435 27.3027L20.0345 20.588L24.0255 27.3027L28.0164 20.588L32.4015 28.1832"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
  </svg>
)
