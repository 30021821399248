import { select, takeEvery } from "redux-saga/effects"

import { LegendTypes } from "library/common/types/legend"
import localStorage from "library/utilities/localStorage"
import { getIsLegendOpen } from "../selectors/legend"

function* toggleLegendSaga() {
  const isLegendOpen: boolean = yield select(getIsLegendOpen)
  localStorage.setItem("is_legend_open", isLegendOpen)
}

export default function* legendSaga() {
  yield takeEvery(LegendTypes.TOGGLE_LEGEND, toggleLegendSaga)
}
