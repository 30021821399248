export function groupBy<K, V>(
  allItems: V[] | undefined,
  getKey: (item: V) => K
): Map<K, V[]> {
  const grouped = new Map<K, V[]>()
  allItems?.forEach((item) => {
    const key = getKey(item)
    const items = grouped.get(key)
    if (items) items.push(item)
    else grouped.set(key, [item])
  })
  return grouped
}
