import { createAction } from "typesafe-actions"

import { Modals } from "library/common/reducers/modalsReducer"

import { ModalTypes } from "../types/modalTypes"

export const openModal = createAction(ModalTypes.OPEN_MODAL)<Modals | null>()
export const closeModal = createAction(ModalTypes.CLOSE_MODAL)()
export const setNewAIVersionModalShownIds = createAction(
  ModalTypes.SET_NEW_AI_VERSION_MODAL_SHOWN_IDS
)<string>()
