import React from "react"

export const CariesProIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4589 4.619C15.6354 5.1142 15.7098 5.72052 15.6972 6.40106L14.2442 7.12847C14.3406 6.29243 14.3151 5.57984 14.1405 5.08996C13.6131 3.61675 12.9276 3.4752 10.7929 3.93712C9.90565 4.12882 9.49575 4.19663 8.99966 4.19663C8.50358 4.19663 8.09388 4.12885 7.20786 3.93741C7.16636 3.92853 7.16636 3.92853 7.12495 3.91968C6.78461 3.847 6.58685 3.80768 6.37825 3.77363C4.98273 3.54581 4.31278 3.81668 3.85935 5.08857C3.42302 6.3155 3.91566 8.92152 4.91428 11.3772L4.90957 11.3659C4.98563 11.5439 5.01335 11.747 4.98685 11.8894C4.90828 12.7345 4.8699 13.5963 4.8699 14.4793C4.8699 17.4113 5.39343 20.2962 5.76039 20.2962C6.07926 20.2962 6.2798 19.6865 6.46382 17.7405C6.46635 17.7137 6.46635 17.7137 6.46891 17.6866C6.79123 14.2913 7.25263 13.0407 8.99966 13.0407C10.7648 13.0407 11.2373 14.3134 11.5578 17.7275C11.5604 17.7547 11.5604 17.7547 11.563 17.7819C11.7459 19.6945 11.944 20.3 12.239 20.3C12.603 20.3 13.1261 17.4125 13.1261 14.4804C13.1289 13.7472 13.1003 13.006 13.0418 12.27L14.4915 12.997C14.505 13.2677 14.5146 13.538 14.5203 13.8074L14.5261 14.4831C14.5261 18.6349 13.9708 21.7 12.239 21.7C10.7538 21.7 10.4394 20.739 10.1693 17.9152C10.1667 17.8879 10.1667 17.8879 10.164 17.8595C9.91845 15.2433 9.62045 14.4407 8.99966 14.4407C8.39927 14.4407 8.10854 15.2288 7.86268 17.8187L7.85761 17.8723C7.58819 20.7213 7.26753 21.6962 5.76039 21.6962C4.02602 21.6962 3.4699 18.6318 3.4699 14.4793C3.4699 13.5538 3.51021 12.6489 3.59991 11.7064L3.58866 11.834L3.49069 11.5861C2.4979 9.03543 2.01476 6.42549 2.47765 4.81499L2.54047 4.61896C3.25561 2.61295 4.60809 2.06611 6.60382 2.39192C6.84086 2.43061 7.05613 2.47342 7.41733 2.55056C7.45923 2.55951 7.45923 2.55951 7.50215 2.5687C8.2879 2.73847 8.63948 2.79663 8.99966 2.79663C9.35986 2.79663 9.71161 2.73844 10.497 2.56874C13.2392 1.97537 14.6141 2.25928 15.4589 4.619Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49966 10L21.4997 3.5V16.5L8.49966 10ZM20.0997 14.2347V5.76524L11.6302 10L20.0997 14.2347Z"
      fill="currentColor"
    />
  </svg>
)
