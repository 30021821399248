import React from "react"

export const MoveIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57407 6.71362H19.295V8.21362H7.57632L10.2646 10.9027L9.21289 11.9636L4.71289 7.46362L9.21289 2.96362L10.2646 4.0242L7.57407 6.71362Z"
      fill="currentColor"
    />
    <path
      d="M16.4328 15.7864H4.70491V17.2864H16.4305L13.7422 19.9754L14.7939 21.0364L19.2939 16.5364L14.7939 12.0364L13.7422 13.097L16.4328 15.7864Z"
      fill="currentColor"
    />
  </svg>
)
