import { createAction } from "typesafe-actions"

import { entitiesTypes } from "library/common/types/entitiesTypes"
import {
  Detection,
  Boneloss,
  ToothSegment,
  HistoricalResult,
  NerveCanal,
  ICroppedTeeth,
} from "library/common/types/dataStructureTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"

export const saveAnnotations = createAction(entitiesTypes.SAVE_DETECTIONS)<{
  apical: Detection[]
  boneloss?: Boneloss
  caries: Detection[]
  restorations: Detection[]
  detectedTeeth: Tooth[]
  calculus: Detection[]
  nervus: Detection[]
  segments: ToothSegment[]
  impacted: Detection[]
  historicalResults: HistoricalResult[]
  nerveCanal: NerveCanal[]
  croppedTeeth?: ICroppedTeeth
}>()
export const setInitialState = createAction(entitiesTypes.SET_INITIAL_STATE)()
export const setImageId = createAction(entitiesTypes.SET_IMAGE_ID)<string>()
export const setViewed = createAction(entitiesTypes.SET_VIEWED)<boolean>()
export const addDetectedTeeth = createAction(entitiesTypes.ADD_DETECTED_TEETH)<
  Tooth[]
>()
export const deleteDetectedTooth = createAction(
  entitiesTypes.DELETE_DETECTED_TOOTH
)<Tooth>()
