import React from "react"

export const HiSensIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25033 17.6334C12.3615 17.6334 14.8837 15.1112 14.8837 12C14.8837 8.88882 12.3615 6.36669 9.25033 6.36669C6.13912 6.36669 3.61699 8.88882 3.61699 12C3.61699 15.1112 6.13912 17.6334 9.25033 17.6334ZM9.25033 19.3334C13.3004 19.3334 16.5837 16.0501 16.5837 12C16.5837 7.94993 13.3004 4.66669 9.25033 4.66669C5.20024 4.66669 1.91699 7.94993 1.91699 12C1.91699 16.0501 5.20024 19.3334 9.25033 19.3334Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5837 4.90502V6.82085C18.7195 7.57252 20.2503 9.60752 20.2503 12C20.2503 14.3925 18.7195 16.4275 16.5837 17.1792V19.095C19.7462 18.2792 22.0837 15.4192 22.0837 12C22.0837 8.58085 19.7462 5.72085 16.5837 4.90502Z"
      fill="currentColor"
    />
  </svg>
)
