import * as actions from "library/common/actions/withHistory"

import { Action, ActionType, getType } from "typesafe-actions"

type WithHistoryActions = ActionType<typeof actions>

const withHistory = <T, A extends Action>(reducer: (t: T, a: A) => T) => {
  type HistoryState<T> = {
    past: T[]
    present: T
    future: T[]
  }
  const initialState: HistoryState<T> = {
    past: [],
    present: reducer(undefined!, {} as A),
    future: [],
  }
  return (
    state: HistoryState<T> = initialState,
    action: A | WithHistoryActions
  ) => {
    const { past, present, future } = state
    switch (action.type) {
      case getType(actions.rememberState):
        return { ...state, past: [...past, present] }
      case getType(actions.redo):
        const next = future[0]
        const newFuture = future.slice(1)
        return {
          past: [...past, present],
          present: next,
          future: newFuture,
        }
      case getType(actions.undo):
        const previous = past[past.length - 1]
        const newPast = past.slice(0, past.length - 1)
        return {
          past: newPast,
          present: previous,
          future: [present, ...future],
        }
      case getType(actions.reset):
        return {
          past: [],
          present: past[0],
          future: [...past.slice(1), present, ...future],
        }
      case getType(actions.setInitialState):
        return initialState
      default:
        const newPresent = reducer(state.present, action as A)
        return { ...state, present: newPresent, future: [] }
    }
  }
}

export default withHistory
