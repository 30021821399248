const deepAnnotationsMerge = (collections: any) =>
  collections.reduce((accumulator: Record<string, any>, collection: any) => {
    if (!collection) return accumulator
    Object.keys(collection).forEach((toothName: string) => {
      const key = Object.keys(collection[toothName])[0]
      if (!accumulator[toothName]) accumulator[toothName] = {}
      accumulator[toothName][key] = collection[toothName][key]
    })

    return accumulator
  }, {})

export default deepAnnotationsMerge
