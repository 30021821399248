import React from "react"

export const DistributorIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M3.7392 12.3046L3.7392 33.5163L27.4332 44.0655L41.9491 37.6025V21.8833L27.4332 15.4846V9.85518L18.3367 5.80518L3.7392 12.3046Z"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M3.7392 12.3047L12.8357 16.3547V21.9841L27.4332 28.3463V44.0656"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M41.9496 21.8833L27.4337 28.3463"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M12.8357 21.9841L27.4332 15.4849"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M27.4344 9.85498L12.8369 16.3542"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M6.50854 21.2729L10.0226 22.8374V18.3043L6.50854 16.7397V21.2729Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M6.50854 34.7494V26.0117L12.299 28.5898V37.3275L6.50854 34.7494Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M15.924 25.4639V30.2027L19.4381 31.7672V27.0284L15.924 25.4639Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M21.0547 27.749V32.4878L24.5688 34.0524V29.3136L21.0547 27.749Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M21.0547 34.3799V39.1187L24.5688 40.6832V35.9444L21.0547 34.3799Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M15.924 32.0952V36.834L19.4381 38.3986V33.6598L15.924 32.0952Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M35.1912 27.1367V31.8755L31.6771 33.4401V28.7013L35.1912 27.1367Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M39.8784 25.0488V29.7876L36.3643 31.3522V26.6134L39.8784 25.0488Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M29.7984 37.627V43.0128L32.6434 41.7462V36.3604L29.7984 37.627Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M32.3736 8.61622C31.1954 9.04985 30.0128 9.91711 29.0023 10.9574C27.9919 11.9976 27.1538 13.2107 26.5166 14.571C25.8794 15.9314 25.443 17.4383 25.2248 18.7554C25.0066 20.0724 25.0066 21.1987 25.0066 22.3252C25.3837 21.4392 25.7607 20.5533 26.391 19.5734C27.0213 18.5935 27.9048 17.5202 28.8486 16.7464C29.7924 15.9726 30.7964 15.4986 31.8028 15.2155C32.8093 14.9323 33.818 14.8401 34.5011 14.794C35.1842 14.7479 35.5415 14.7479 35.8987 14.7479L35.8987 18.9011L44.0952 11.4652L35.8987 4.0293L35.8989 8.18274C34.7256 8.18274 33.552 8.18274 32.3738 8.61637L32.3736 8.61622Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
