import React from "react"

export const ActiveCheckbox = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4685 0.5H1.96851C1.63699 0.5 1.31904 0.631696 1.08462 0.866116C0.850202 1.10054 0.718506 1.41848 0.718506 1.75V14.25C0.718506 14.5815 0.850202 14.8995 1.08462 15.1339C1.31904 15.3683 1.63699 15.5 1.96851 15.5H14.4685C14.8 15.5 15.118 15.3683 15.3524 15.1339C15.5868 14.8995 15.7185 14.5815 15.7185 14.25V1.75C15.7185 1.41848 15.5868 1.10054 15.3524 0.866116C15.118 0.631696 14.8 0.5 14.4685 0.5ZM6.96851 11.4375L3.84351 8.33919L4.83776 7.375L6.96851 9.466L11.5989 4.875L12.5938 5.86075L6.96851 11.4375Z"
      fill="#009ACE"
    />
  </svg>
)
