import createSagaMiddleware from "redux-saga"
import { StateType } from "typesafe-actions"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"

import createCoreReducer from "../coreReducer"
import coreSaga from "../coreSaga"
import { createBrowserHistory } from "history"

const sagaMiddleware = createSagaMiddleware()

const middlewares = composeWithDevTools(applyMiddleware(sagaMiddleware))

export const history = createBrowserHistory()

// for the MIDC / Scanner integration, we do not want to push new entries to the history
const sourceParam = new URLSearchParams(location.search).get("source")
if (sourceParam && /web|desktop/i.exec(sourceParam)) {
  history.push = history.replace.bind(history)
}

const coreReducer = createCoreReducer(history)

export type RootState = StateType<typeof coreReducer>

export default function configureStore(initialState = {}) {
  const configuredStore = createStore(coreReducer, initialState, middlewares)
  sagaMiddleware.run(coreSaga)

  return configuredStore
}

export const store = configureStore()
