import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/modal"

export enum Modals {
  SELECT_RADIOGRAPH = "SELECT_RADIOGRAPH",
  ROTATE_X_RAY_IMAGE = "ROTATE_X_RAY_IMAGE",
  CHANGE_IMAGE_META = "CHANGE_IMAGE_META",
  RESET_PREDICTIONS = "RESET_PREDICTIONS",
  REANALYSE_ALERT = "REANALYSE_ALERT",
  TOOTH_BASED_PERI_ALERT = "TOOTH_BASED_PERI_ALERT",
  ROTATE_OPTIONS_MODAL = "ROTATE_OPTIONS_MODAL",
  NEW_AI_VERSION_MODAL = "NEW_AI_VERSION_MODAL",
  NEW_USER_ONBOARDING_MODAL = "NEW_USER_ONBOARDING_MODAL",
  NO_SUBSCRIPTION_MODAL = "NO_SUBSCRIPTION_MODAL",
  EXTENDED_REPORT_MODAL = "EXTENDED_REPORT_MODAL",
}

type ModalState = Readonly<{
  openedModal: Modals | null
  newAIVersionModalShownIds: string[]
}>

const initialState: ModalState = {
  openedModal: null,
  newAIVersionModalShownIds: [],
}

type ModalActions = ActionType<typeof actions>

export default (state = initialState, action: ModalActions): ModalState => {
  switch (action.type) {
    case getType(actions.openModal):
      return { ...state, openedModal: action.payload }

    case getType(actions.closeModal):
      return { ...state, openedModal: null }
    case getType(actions.setNewAIVersionModalShownIds):
      return {
        ...state,
        newAIVersionModalShownIds: [
          ...state.newAIVersionModalShownIds,
          action.payload,
        ],
      }

    default:
      return state
  }
}
