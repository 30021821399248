import { Kind } from "./serverDataTypes"

export enum imageTypes {
  LOAD_IMAGE = "@@IMAGE/LOAD_IMAGE",
  LOAD_IMAGE_SUCCESS = "@@IMAGE/LOAD_IMAGE_SUCCESS",
  SAVE_IMAGE_META = "@@IMAGE/SAVE_IMAGE_META",
  IMAGE_PROCESSING_COMPLETE = "@@IMAGE/IMAGE_PROCESSING_COMPLETE",
  LOAD_ANNOTATIONS = "@@IMAGE/LOAD_ANNOTATIONS",
  LOAD_ANNOTATIONS_SUCCESS = "@@IMAGE/LOAD_ANNOTATIONS_SUCCESS",
  SET_SHOWN_RADIOGRAPH_ANNOTATIONS = "@@IMAGE/SET_SHOWN_RADIOGRAPH_ANNOTATIONS",
  SET_INITIAL_STATE = "@@IMAGE/SET_INITIAL_STATE",
  ROTATE_IMAGE = "@@IMAGE/ROTATE_IMAGE",
  SET_AS_NOTPROCESSED = "@@IMAGE/SET_AS_NOTPROCESSED",
  CHANGE_RADIOGRAPH_TYPE = "@@IMAGE/CHANGE_RADIOGRAPH_TYPE",
  UPDATE_OPENDATEMS = "@@IMAGE/UPDATE_OPENDATEMS",
  SET_PDF_REPORT_DATA = "@@SERVER_DATA/SET_PDF_REPORT_DATA",
  LOAD_PDF_REPORT = "@@IMAGE/LOAD_PDF_REPORT",
  LOAD_PDF_REPORT_SUCCESS = "@@IMAGE/LOAD_PDF_REPORT_SUCCESS",
  OPEN_PDF_REPORT = "@@IMAGE/OPEN_PDF_REPORT",
  SET_SHOW_IMMEDIATELY = "@@IMAGE.SET_SHOW_IMMEDIATELY",
  REANALYZE_IMAGE = "@@IMAGE/REANALYZE_IMAGE",
  REVERT_IMAGE = "@@IMAGE/REVERT_IMAGE",
  SET_ACCEPT_MISSING_METADATA = "@@IMAGE/SET_ACCEPT_MISSING_METADATA",
  SAVE_REPORT = "@@IMAGE/SAVE_REPORT",
  COPY_REPORT_AS_IMAGE = "@@IMAGE/COPY_REPORT_AS_IMAGE",
  COPY_REPORT_AS_TEXT = "@@IMAGE/COPY_REPORT_AS_TEXT",
  SEND_REPORT = "@@IMAGE/SEND_REPORT",
  SET_IMAGE_RESULT_STATUS = "@@IMAGE/SET_IMAGE_RESULT_STATUS",
}

export type PdfReport = Readonly<{
  id: string
  isBoneLossPdf: boolean
}>

export type PdfReportData = Readonly<{
  pdfReportData: string
  textAnnotations: string
}>

export type PdfData = Readonly<{
  data: Readonly<{
    pdf: string
    text: string
  }>
}>

export type RotateData = Readonly<{
  imageId: string
  rotate: number
  isFlipped: boolean
}>

export type Position = Readonly<{
  x: number
  y: number
}>

export type RadiographType = Readonly<{
  id: string
  kind: Kind
}>

export type SendReport = Readonly<{
  id: string
  pdfReport: string
}>
