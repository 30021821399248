import React from "react"

export const UngroupSetIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7832_10863)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.58337C0 3.89302 0.559644 3.33337 1.25 3.33337H5.41667C6.10702 3.33337 6.66667 3.89302 6.66667 4.58337V7.91671C6.66667 8.60706 6.10702 9.16671 5.41667 9.16671H1.25C0.559644 9.16671 0 8.60706 0 7.91671V4.58337ZM1.25 4.16671C1.01988 4.16671 0.833333 4.35326 0.833333 4.58337V7.91671C0.833333 8.14683 1.01988 8.33337 1.25 8.33337H5.41667C5.64678 8.33337 5.83333 8.14683 5.83333 7.91671V4.58337C5.83333 4.35326 5.64679 4.16671 5.41667 4.16671H1.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 2.49996C8.51988 2.49996 8.33333 2.68651 8.33333 2.91663V8.1607C8.33333 8.25602 8.4106 8.33329 8.50592 8.33329C8.5517 8.33329 8.59559 8.31511 8.62796 8.28274L11.5446 5.36608C11.6228 5.28794 11.6667 5.18196 11.6667 5.07145V2.91663C11.6667 2.68651 11.4801 2.49996 11.25 2.49996H8.75ZM7.5 2.91663C7.5 2.22627 8.05964 1.66663 8.75 1.66663H11.25C11.9404 1.66663 12.5 2.22627 12.5 2.91663V5.07145C12.5 5.40297 12.3683 5.72091 12.1339 5.95533L9.21722 8.872C9.02857 9.06065 8.77271 9.16663 8.50592 9.16663C7.95037 9.16663 7.5 8.71626 7.5 8.1607V2.91663Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1824 3.64526C18.3821 3.44556 18.6529 3.33337 18.9353 3.33337C19.5234 3.33337 20.0002 3.81012 20.0002 4.39822V7.91671C20.0002 8.60706 19.4405 9.16671 18.7502 9.16671H13.8091C13.5464 9.16671 13.3335 8.95378 13.3335 8.69112C13.3335 8.56498 13.3836 8.44401 13.4728 8.35482L18.1824 3.64526ZM18.9353 4.16671C18.8739 4.16671 18.815 4.1911 18.7716 4.23452L14.6728 8.33337H18.7502C18.9803 8.33337 19.1668 8.14683 19.1668 7.91671V4.39822C19.1668 4.27036 19.0632 4.16671 18.9353 4.16671Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3335 12.0834C13.3335 11.393 13.8931 10.8334 14.5835 10.8334H18.7502C19.4405 10.8334 20.0002 11.393 20.0002 12.0834V15.4167C20.0002 16.1071 19.4405 16.6667 18.7502 16.6667H14.5835C13.8931 16.6667 13.3335 16.1071 13.3335 15.4167V12.0834ZM14.5835 11.6667C14.3534 11.6667 14.1668 11.8533 14.1668 12.0834V15.4167C14.1668 15.6468 14.3534 15.8334 14.5835 15.8334H18.7502C18.9803 15.8334 19.1668 15.6468 19.1668 15.4167V12.0834C19.1668 11.8533 18.9803 11.6667 18.7502 11.6667H14.5835Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4941 11.6667C11.4483 11.6667 11.4044 11.6849 11.372 11.7173L8.45537 14.6339C8.37723 14.7121 8.33333 14.818 8.33333 14.9286V17.0834C8.33333 17.3135 8.51988 17.5 8.75 17.5H11.25C11.4801 17.5 11.6667 17.3135 11.6667 17.0834V11.8393C11.6667 11.744 11.5894 11.6667 11.4941 11.6667ZM10.7828 11.128C10.9714 10.9394 11.2273 10.8334 11.4941 10.8334C12.0496 10.8334 12.5 11.2837 12.5 11.8393V17.0834C12.5 17.7737 11.9404 18.3334 11.25 18.3334H8.75C8.05964 18.3334 7.5 17.7737 7.5 17.0834V14.9286C7.5 14.597 7.6317 14.2791 7.86612 14.0447L10.7828 11.128Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.0834C0 11.393 0.559644 10.8334 1.25 10.8334H6.19107C6.45374 10.8334 6.66667 11.0463 6.66667 11.309C6.66667 11.4351 6.61656 11.5561 6.52737 11.6453L1.81781 16.3548C1.61811 16.5545 1.34726 16.6667 1.06485 16.6667C0.476749 16.6667 0 16.19 0 15.6019V12.0834ZM1.25 11.6667C1.01988 11.6667 0.833333 11.8533 0.833333 12.0834V15.6019C0.833333 15.7297 0.936986 15.8334 1.06485 15.8334C1.12625 15.8334 1.18514 15.809 1.22855 15.7656L5.32741 11.6667H1.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1922 1.69199L1.27548 19.6087L0.391602 18.7248L18.3083 0.808105L19.1922 1.69199Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7832_10863">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
