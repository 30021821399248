import React from "react"

export const ContrastIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8954 5.92C14.7612 5.48498 14.5837 5.06454 14.3654 4.665C14.0428 4.07201 13.637 3.52817 13.1604 3.05C12.1814 2.0711 10.9342 1.40449 9.57631 1.13445C8.21846 0.864412 6.81103 1.00308 5.53199 1.53291C4.25295 2.06275 3.15974 2.95995 2.3906 4.11108C1.62146 5.26221 1.21094 6.61556 1.21094 8C1.21094 9.38444 1.62146 10.7378 2.3906 11.8889C3.15974 13.04 4.25295 13.9373 5.53199 14.4671C6.81103 14.9969 8.21846 15.1356 9.57631 14.8655C10.9342 14.5955 12.1814 13.9289 13.1604 12.95C13.637 12.4718 14.0428 11.928 14.3654 11.335C14.5837 10.9355 14.7612 10.515 14.8954 10.08C15.317 8.72537 15.317 7.27463 14.8954 5.92ZM2.21043 8C2.21043 6.4087 2.84257 4.88258 3.96779 3.75736C5.09301 2.63214 6.61913 2 8.21043 2V14C6.61913 14 5.09301 13.3679 3.96779 12.2426C2.84257 11.1174 2.21043 9.5913 2.21043 8Z"
      fill="currentColor"
    />
  </svg>
)
