const mapAnnotationsByType = (data: any, key: string) => {
  if (!data) return

  return data.reduce((c: any, n: any) => {
    if (!c[n.toothName]) c[n.toothName] = { [key]: [] }
    c[n.toothName][key] = c[n.toothName][key].concat(n)

    return c
  }, {})
}

export default mapAnnotationsByType
