import { PatientList } from "library/common/types/patientTypes"

export const filterSections = [
  {
    heading: {
      text: "app.patient.patient_filter.recent_radiograph",
      value: "recentRadiographs",
    },
    item: [
      { text: "app.patient.patient_filter.read", value: "read" },
      { text: "app.patient.patient_filter.unread", value: "unread" },
    ],
    match: (patient: PatientList, selected: string[]) =>
      selected.includes(patient.viewed ? "read" : "unread"),
  },
  {
    heading: { text: "app.patient.modalities", value: "modalities" },
    item: [
      { text: "app.viewer.xrayType_OPG", value: "OPG" },
      { text: "app.viewer.xrayType_BW", value: "BW" },
      { text: "app.viewer.xrayType_PERI", value: "PERI" },
      { text: "app.OTHER_full_name", value: "OTHER" },
    ],
    match: (patient: PatientList, selected: string[]) =>
      Object.entries(patient.modalities).some(
        ([modality, count]) => count > 0 && selected.includes(modality)
      ),
  },
  {
    heading: {
      text: "app.patient.patient_filter.report_created",
      value: "reportCreated",
    },
    item: [
      { text: "app.yes", value: "reportCreatedYes" },
      { text: "app.no", value: "reportCreatedNo" },
    ],
    match: (patient: PatientList, selected: string[]) =>
      selected.includes(
        !!patient.reportDate ? "reportCreatedYes" : "reportCreatedNo"
      ),
  },
  {
    heading: { text: "app.patient.notes", value: "notes" },
    item: [
      { text: "app.yes", value: "notesYes" },
      { text: "app.no", value: "notesNo" },
    ],
    match: (patient: PatientList, selected: string[]) =>
      selected.includes(patient.comments?.length ? "notesYes" : "notesNo"),
  },
]
