import React from "react"

export const OrientationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.375 0.416626L8.49375 1.29788L10.1063 2.91663H6.875C5.71507 2.91792 4.60302 3.37927 3.78283 4.19946C2.96264 5.01965 2.50129 6.1317 2.5 7.29163V10.4166H3.75V7.29163C3.75094 6.46311 4.08049 5.66881 4.66633 5.08296C5.25218 4.49711 6.04649 4.16757 6.875 4.16663H10.1063L8.49375 5.78538L9.375 6.66663L12.5 3.54163L9.375 0.416626Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0694 18.5416H16.2269V17.3842H15.0694V18.5416ZM17.3843 11.5972H18.5417V10.4398H17.3843V11.5972ZM8.125 9.28234V17.3842C8.125 18.0208 8.64583 18.5416 9.28241 18.5416H11.5972V17.3842H9.28241V9.28234H11.5972V8.12494H9.28241C8.64583 8.12494 8.125 8.64577 8.125 9.28234ZM17.3843 8.12494V9.28234H18.5417C18.5417 8.64577 18.0208 8.12494 17.3843 8.12494ZM12.7546 19.699H13.912V6.96753H12.7546V19.699ZM17.3843 16.2268H18.5417V15.0694H17.3843V16.2268ZM15.0694 9.28234H16.2269V8.12494H15.0694V9.28234ZM17.3843 13.912H18.5417V12.7546H17.3843V13.912ZM17.3843 18.5416C18.0208 18.5416 18.5417 18.0208 18.5417 17.3842H17.3843V18.5416Z"
      fill="currentColor"
    />
  </svg>
)
