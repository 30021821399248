import React from "react"

export const UKCAMarkingIcon = () => (
  <svg
    width="72"
    height="44"
    viewBox="0 0 72 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.5586 33.6602L50.9473 35.9688L52.3535 33.6602H53.625L51.5508 36.7891L53.6895 40H52.4355L50.9707 37.6211L49.5059 40H48.2461L50.3789 36.7891L48.3105 33.6602H49.5586ZM55.5117 33.6602L56.9004 35.9688L58.3066 33.6602H59.5781L57.5039 36.7891L59.6426 40H58.3887L56.9238 37.6211L55.459 40H54.1992L56.332 36.7891L54.2637 33.6602H55.5117ZM61.4648 33.6602L62.8535 35.9688L64.2598 33.6602H65.5312L63.457 36.7891L65.5957 40H64.3418L62.877 37.6211L61.4121 40H60.1523L62.2852 36.7891L60.2168 33.6602H61.4648ZM67.418 33.6602L68.8066 35.9688L70.2129 33.6602H71.4844L69.4102 36.7891L71.5488 40H70.2949L68.8301 37.6211L67.3652 40H66.1055L68.2383 36.7891L66.1699 33.6602H67.418Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_2162_122020)">
      <mask
        id="mask0_2162_122020"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="41"
      >
        <path
          d="M0.158569 0.221191H40.1245V40.1539H0.158569V0.221191Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2162_122020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0414 18.5237H6.56888C3.03163 18.5237 0.153839 15.6845 0.153839 12.1946V0.221252H4.31352V12.1387C4.31352 13.3657 5.35038 14.3641 6.62478 14.3641H11.9854C13.2598 14.3641 14.2967 13.3657 14.2967 12.1387V0.221252H18.4564V12.1946C18.4564 15.6845 15.5786 18.5237 12.0414 18.5237Z"
          fill="currentColor"
        />
      </g>
      <mask
        id="mask1_2162_122020"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="41"
      >
        <path
          d="M0.158569 0.221191H40.1245V40.1539H0.158569V0.221191Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2162_122020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1245 0.22127H34.7454L25.9436 7.14804V0.221191H21.784V18.5237H25.9436V11.5513L34.7449 18.5237H40.1015L28.5232 9.35119L40.1245 0.22127Z"
          fill="currentColor"
        />
      </g>
      <mask
        id="mask2_2162_122020"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="41"
      >
        <path
          d="M0.158569 0.221191H40.1245V40.1539H0.158569V0.221191Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2162_122020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4564 40.154H6.48313C2.99313 40.154 0.153839 37.2203 0.153839 33.683V28.3223C0.153839 24.7851 2.99313 21.8514 6.48313 21.8514H18.4564V26.011H6.53896C5.31187 26.011 4.31352 27.0479 4.31352 28.3223V33.683C4.31352 34.9573 5.31187 35.9942 6.53896 35.9942H18.4564V40.154Z"
          fill="currentColor"
        />
      </g>
      <mask
        id="mask3_2162_122020"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="41"
      >
        <path
          d="M0.158569 0.221191H40.1245V40.1539H0.158569V0.221191Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2162_122020)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9436 31.8343V28.2363C25.9436 27.0092 26.9805 26.0108 28.2549 26.0108H33.6156C34.89 26.0108 35.9268 27.0092 35.9268 28.2363V31.8343H25.9436ZM33.6715 21.8512H28.199C24.6617 21.8512 21.784 24.6905 21.784 28.1804V40.154H25.9436V35.994H35.9268V40.154H40.0864V28.1804C40.0864 24.6905 37.2087 21.8512 33.6715 21.8512Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2162_122020">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(0.153839 0.15387)"
        />
      </clipPath>
    </defs>
  </svg>
)
