import React from "react"

export const MedicalDeviceIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <rect
      x="4.57692"
      y="11.5769"
      width="38.8462"
      height="25"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.15385"
    />
    <path
      d="M8.95924 31.7693V16.627H13.535L16.2825 26.956L18.9991 16.627H23.5851V31.7693H20.7447V19.8496L17.7389 31.7693H14.7951L11.7997 19.8496V31.7693H8.95924Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M26.6115 16.627H32.1996C33.4597 16.627 34.4203 16.7234 35.0813 16.9162C35.9696 17.1778 36.7305 17.6426 37.3641 18.3106C37.9976 18.9785 38.4796 19.798 38.8101 20.7689C39.1407 21.7329 39.3059 22.9242 39.3059 24.3427C39.3059 25.5891 39.151 26.6633 38.8411 27.5654C38.4624 28.6672 37.9218 29.5589 37.2195 30.2406C36.6892 30.7571 35.9731 31.1599 35.071 31.4491C34.3962 31.6626 33.4941 31.7693 32.3648 31.7693H26.6115V16.627ZM29.6689 19.1886V29.218H31.9517C32.8055 29.218 33.4218 29.1698 33.8005 29.0734C34.2963 28.9495 34.7061 28.7395 35.0297 28.4434C35.3602 28.1473 35.6288 27.6618 35.8354 26.987C36.0419 26.3053 36.1452 25.3791 36.1452 24.2085C36.1452 23.0378 36.0419 22.1392 35.8354 21.5126C35.6288 20.886 35.3396 20.397 34.9677 20.0459C34.5959 19.6947 34.1242 19.4571 33.5527 19.3332C33.1257 19.2368 32.2891 19.1886 31.0427 19.1886H29.6689Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
