import {
  AnnotationName,
  RestorationSubtype,
} from "library/common/types/adjustmentTypes"

// TODO (carlos): sync accepted HSM subtypes. So it can be updated only at one place
//  - related to and must sync with `isHighSensitivityMode`
export const getAnnotationNameType = (type: string) => {
  switch (type) {
    case "caries":
    case "caries_HSM":
    case "caries_F2":
      return AnnotationName.caries
    case "apical":
    case "apical_HSM":
    case "apical_F2":
      return AnnotationName.apical
    case "calculus":
      return AnnotationName.calculus
    case "nervus":
      return AnnotationName.nervus
    case "impacted":
      return AnnotationName.impacted
    default:
      return AnnotationName.restorations
  }
}

export const getRestorationSubtype: (
  subtype: string
) => RestorationSubtype | undefined = (subtype: string) => {
  switch (subtype) {
    case "fillings":
      return RestorationSubtype.fillings
    case "bridges":
      return RestorationSubtype.bridges
    case "implants":
      return RestorationSubtype.implants
    case "crowns":
      return RestorationSubtype.crowns
    case "roots":
      return RestorationSubtype.roots
    default:
      return undefined
  }
}
