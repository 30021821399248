import { CssBaseline, Typography } from "@align-cpf/uikit"
import { ICssBaseLineTheme } from "@align-cpf/uikit/lib/components/atoms/CssBaseline/CssBaseline.context"
import * as React from "react"
import { useTheme } from "./Baseline.context"
import useStyles from "./Baseline.styles"
import { designTokens } from "@align-cpf/uikit-styles"
import { DEFAULT_THEME } from "library/utilities/constants"
import { Theme } from "library/common/actions/user"

export interface IBaselineProps {
  theme: ICssBaseLineTheme
  children: React.ReactNode
}

const style = document.createElement("style")
style.setAttribute("data-id", ":root")
document.head.appendChild(style)

export const Baseline: React.FC<IBaselineProps> = ({ theme, children }) => {
  const classes = useStyles({ theme })

  React.useEffect(() => {
    const appHeight = () => {
      style.innerHTML = `
:root {
  --app-height: ${window.innerHeight}px;
  --backgroundInverse: ${designTokens[theme]["--backgroundInverse"]};
  --textInverse: ${designTokens[theme]["--textInversePrimary"]};
  --sp02: ${designTokens.spacing["--sp02"]};
  --tpBody01: ${designTokens.typography["--tpBody01"]};
  color-scheme: ${theme};
}

html {
  overflow: hidden;
}

body {
  background: ${designTokens[theme]["--backgroundSubtle01"]} !important;
  background-color: ${
    DEFAULT_THEME === Theme.carestream
      ? "white"
      : designTokens[theme]["--backgroundSubtle01"]
  } !important;
}
      `
    }

    window.addEventListener("resize", appHeight)
    appHeight()
    return () => {
      window.removeEventListener("resize", appHeight)
    }
  }, [theme])

  return (
    <CssBaseline theme={theme}>
      <Typography as="div" className={classes.root}>
        {children}
      </Typography>
    </CssBaseline>
  )
}

const ContextBaseline: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { theme } = useTheme()

  return <Baseline theme={theme}>{children}</Baseline>
}

export default ContextBaseline
