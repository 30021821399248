import React from "react"

export const CheckmarkFill = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.375 1.5C10.2983 1.5 8.26823 2.11581 6.54152 3.26957C4.8148 4.42332 3.46899 6.0632 2.67427 7.98182C1.87955 9.90045 1.67161 12.0116 2.07676 14.0484C2.4819 16.0852 3.48193 17.9562 4.95038 19.4246C6.41883 20.8931 8.28976 21.8931 10.3266 22.2982C12.3634 22.7034 14.4746 22.4955 16.3932 21.7007C18.3118 20.906 19.9517 19.5602 21.1054 17.8335C22.2592 16.1068 22.875 14.0767 22.875 12C22.875 9.21523 21.7688 6.54451 19.7996 4.57538C17.8305 2.60625 15.1598 1.5 12.375 1.5ZM10.875 16.1931L7.125 12.4431L8.31796 11.25L10.875 13.8069L16.4325 8.25L17.6293 9.43943L10.875 16.1931Z"
      fill="currentColor"
    />
  </svg>
)
