import { createAction } from "typesafe-actions"
import { breadcrumbTypes } from "../types/breadcrumbTypes"

export const setInitialBreadcrumbState = createAction(
  breadcrumbTypes.SET_INITIAL_BREADCRUMB_STATE
)()
export const setPatientFileBreadcrumb = createAction(
  breadcrumbTypes.SET_PATIENT_FILE_BREADCRUMB
)<string>()
export const setImageIDBreadcrumb = createAction(
  breadcrumbTypes.SET_IMAGE_ID_BREADCRUMB
)<string>()
export const setReportBreadcrumb = createAction(
  breadcrumbTypes.SET_REPORT_BREADCRUMB
)<string>()
