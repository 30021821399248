import React from "react"

export const RedoIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.5H18.1388L15.4483 4.81058L16.5 3.75L21 8.25L16.5 12.75L15.4483 11.689L18.1366 9H9C7.80653 9 6.66193 9.47411 5.81802 10.318C4.97411 11.1619 4.5 12.3065 4.5 13.5C4.5 14.6935 4.97411 15.8381 5.81802 16.682C6.66193 17.5259 7.80653 18 9 18H15V19.5H9C7.4087 19.5 5.88258 18.8679 4.75736 17.7426C3.63214 16.6174 3 15.0913 3 13.5C3 11.9087 3.63214 10.3826 4.75736 9.25736C5.88258 8.13214 7.4087 7.5 9 7.5Z"
      fill="currentColor"
    />
  </svg>
)
