import { Location } from "history"
import localStorage from "library/utilities/localStorage"
import { store } from "core/store/configureStore"

export const hideToken = (params: URLSearchParams) => {
  params.delete("token")
  window.history.replaceState(null, "", `?${params}`)
}

const encodeTwice = (s: string) => encodeURIComponent(encodeURIComponent(s))

export const patientFileUrl = (patientUuid: string | null) =>
  patientUuid ? `/patients/${encodeTwice(patientUuid)}` : "/unassigned-patient/"

export const isDashboard = (location: Location<unknown>) =>
  location.pathname.startsWith("/dashboard")

export const isPatientFile = (location: Location<unknown>) =>
  location.pathname.includes("patients") ||
  location.pathname.includes("unassigned-patient")

export const defaultRoute = () => {
  const isIteroScannerUser = store.getState().user.isIteroScannerUser
  return (
    sessionStorage.getItem("last_url") ||
    (localStorage.getItem("installer_by_default") || isIteroScannerUser
      ? "/patients"
      : "/upload")
  )
}
