import React from "react"

export const WarningIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M6.46008 39.473L24 9.125L41.5399 39.473H6.46008Z"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="1.2"
    />
    <path
      d="M24 15.7979V31.4739"
      stroke="black"
      strokeOpacity="0.93"
      strokeWidth="2.4"
    />
    <path
      d="M24 37.4422C25.0723 37.4422 25.9416 36.5729 25.9416 35.5006C25.9416 34.4283 25.0723 33.5591 24 33.5591C22.9277 33.5591 22.0585 34.4283 22.0585 35.5006C22.0585 36.5729 22.9277 37.4422 24 37.4422Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
