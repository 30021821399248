import { Detection, UserChange } from "library/common/types/dataStructureTypes"

export const isHighSensitivityMode = (subtype: string) =>
  subtype.endsWith("_HSM") || subtype.endsWith("_F2")

export const filterSensAnnotations = (
  annotations: Detection[],
  isHighSense: boolean,
  acceptedChanges: UserChange[]
) => {
  if (!annotations) return annotations

  return Object.keys(annotations).reduce((accumulator: any, toothName: any) => {
    const annotation = annotations[toothName]
    const labels = Object.values(annotation)[0] as any
    const key = Object.keys(annotation)[0]

    const senseAnnotation = labels.filter((label: any) => {
      const change = acceptedChanges.find(
        (change: { annotationId: number }) => change.annotationId === label.id
      )
      if (change?.action === "accepted") return true
      if (change?.action === "rejected" && !change?.isHSM) return false

      return isHighSense === isHighSensitivityMode(label.subtype || "")
    })

    if (senseAnnotation.length) {
      accumulator[toothName] = { [key]: senseAnnotation }
    }

    return accumulator
  }, {})
}
