import React from "react"

export const MoonIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1045_7706)">
      <path
        d="M8.43923 3.3835C8.1527 4.60677 8.11372 5.87498 8.32456 7.11354C8.53541 8.3521 8.99183 9.53598 9.66698 10.5955C10.3421 11.6551 11.2224 12.5689 12.2559 13.2833C13.2894 13.9976 14.4554 14.498 15.6852 14.7551C15.0386 15.424 14.2642 15.9562 13.408 16.3201C12.5518 16.684 11.6312 16.8721 10.7009 16.8734C10.6143 16.8734 10.527 16.8766 10.4397 16.8734C8.82014 16.8161 7.27179 16.1933 6.06358 15.1133C4.85538 14.0332 4.06366 12.564 3.82594 10.9609C3.58821 9.35786 3.91949 7.72216 4.76224 6.33792C5.60499 4.95369 6.90597 3.90836 8.43923 3.3835ZM9.36267 1.875C9.32608 1.87506 9.28956 1.87832 9.25354 1.88475C7.26339 2.23824 5.47442 3.31563 4.23122 4.90941C2.98802 6.50318 2.37852 8.5006 2.52012 10.5169C2.66172 12.5333 3.5444 14.4259 4.99815 15.8303C6.4519 17.2347 8.37389 18.0514 10.3939 18.1233C10.4965 18.1271 10.599 18.1233 10.7007 18.1233C12.0125 18.124 13.3051 17.8088 14.4694 17.2045C15.6336 16.6001 16.6352 15.7243 17.3895 14.6511C17.4506 14.5586 17.4863 14.4516 17.493 14.3409C17.4996 14.2303 17.477 14.1198 17.4273 14.0207C17.3777 13.9215 17.3028 13.8372 17.2102 13.7762C17.1176 13.7153 17.0106 13.6798 16.8999 13.6733C15.6513 13.5638 14.4446 13.1686 13.373 12.5183C12.3015 11.868 11.3938 10.98 10.7202 9.92296C10.0466 8.86592 9.62507 7.66815 9.48821 6.42221C9.35135 5.17628 9.50282 3.91556 9.93092 2.7375C9.96733 2.64325 9.98071 2.54166 9.96993 2.44119C9.95914 2.34072 9.92451 2.24428 9.86892 2.1599C9.81333 2.07552 9.73838 2.00565 9.65032 1.95609C9.56227 1.90653 9.46364 1.87873 9.36267 1.875Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1045_7706">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
