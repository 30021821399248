import { theme } from "@align-cpf/uikit-styles"
import { createUseStyles } from "react-jss"

export default createUseStyles(
  {
    notificationContainer: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      background: theme.colors.backgroundSubtle01,
      zIndex: 25,
    },

    notificationWrapper: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  {
    name: "FullScreenNotification",
  }
)
