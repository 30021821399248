import React from "react"

export const CHREPIcon = () => (
  <svg
    width="48"
    height="16"
    viewBox="0 0 48 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4663 9.64063L11.746 10.0464C11.5498 10.7598 11.2228 11.2904 10.765 11.6382C10.3102 11.983 9.73205 12.1554 9.03052 12.1554C8.16253 12.1554 7.44912 11.8596 6.89028 11.2681C6.33144 10.6736 6.05201 9.86209 6.05201 8.83358C6.05201 7.74562 6.33292 6.90141 6.89474 6.30096C7.45655 5.69753 8.19523 5.39581 9.11078 5.39581C9.9104 5.39581 10.5599 5.63213 11.0593 6.10477C11.3566 6.38419 11.5795 6.78548 11.7281 7.30865L10.4217 7.62077C10.3444 7.2819 10.1824 7.01437 9.93567 6.81818C9.69192 6.62199 9.39466 6.5239 9.0439 6.5239C8.55937 6.5239 8.16551 6.69779 7.8623 7.04558C7.56208 7.39337 7.41196 7.95667 7.41196 8.73548C7.41196 9.56186 7.56059 10.1504 7.85785 10.5012C8.1551 10.8519 8.54153 11.0273 9.01714 11.0273C9.36791 11.0273 9.66962 10.9159 9.92229 10.6929C10.175 10.47 10.3563 10.1192 10.4663 9.64063ZM12.8874 12.0439V5.50728H14.2072V8.08003H16.7934V5.50728H18.1132V12.0439H16.7934V9.18583H14.2072V12.0439H12.8874Z"
      fill="currentColor"
    />
    <path
      d="M27.1274 12.0439V5.50728H29.9052C30.6038 5.50728 31.1106 5.56673 31.4257 5.68564C31.7438 5.80157 31.9979 6.00965 32.1882 6.30987C32.3784 6.6101 32.4735 6.95343 32.4735 7.33987C32.4735 7.83034 32.3294 8.23609 32.041 8.55713C31.7527 8.87519 31.3217 9.07584 30.748 9.15907C31.0333 9.32554 31.2682 9.50835 31.4525 9.70751C31.6397 9.90667 31.8909 10.2604 32.206 10.7687L33.0041 12.0439H31.4257L30.4715 10.6216C30.1326 10.1133 29.9008 9.79372 29.7759 9.66292C29.6511 9.52916 29.5188 9.4385 29.3791 9.39093C29.2394 9.3404 29.0179 9.31513 28.7147 9.31513H28.4472V12.0439H27.1274ZM28.4472 8.27176H29.4237C30.0568 8.27176 30.4522 8.24501 30.6097 8.19151C30.7673 8.138 30.8906 8.04585 30.9798 7.91506C31.069 7.78426 31.1136 7.62077 31.1136 7.42458C31.1136 7.20462 31.0541 7.02775 30.9352 6.89398C30.8193 6.75724 30.6543 6.67104 30.4403 6.63537C30.3333 6.62051 30.0123 6.61308 29.4772 6.61308H28.4472V8.27176ZM33.722 12.0439V5.50728H38.5688V6.61308H35.0418V8.0622H38.3235V9.16353H35.0418V10.9426H38.6936V12.0439H33.722ZM39.8128 12.0439V5.50728H41.9307C42.7333 5.50728 43.2565 5.53998 43.5003 5.60538C43.8748 5.70347 44.1884 5.9175 44.4411 6.24745C44.6937 6.57443 44.8201 6.99802 44.8201 7.51822C44.8201 7.91952 44.7472 8.2569 44.6016 8.53038C44.4559 8.80385 44.2701 9.01936 44.0442 9.17691C43.8213 9.33148 43.5939 9.43404 43.362 9.48457C43.0469 9.54699 42.5906 9.57821 41.9932 9.57821H41.1326V12.0439H39.8128ZM41.1326 6.61308V8.46795H41.8549C42.3751 8.46795 42.7229 8.43377 42.8983 8.3654C43.0737 8.29703 43.2104 8.19002 43.3085 8.04436C43.4096 7.89871 43.4601 7.72927 43.4601 7.53606C43.4601 7.29825 43.3903 7.10206 43.2506 6.94749C43.1108 6.79292 42.934 6.69631 42.72 6.65766C42.5624 6.62794 42.2458 6.61308 41.7702 6.61308H41.1326Z"
      fill="currentColor"
    />
    <rect
      x="0.35122"
      y="0.35122"
      width="23.6488"
      height="15.2195"
      stroke="currentColor"
      strokeWidth="0.702439"
    />
    <rect
      x="24"
      y="0.35122"
      width="23.6488"
      height="15.2195"
      stroke="currentColor"
      strokeWidth="0.702439"
    />
  </svg>
)
