import fetch from "library/utilities/fetch"

interface INewPasswordData {
  oldPassword?: string
  newPassword: string
  fullName?: string
  email?: string
  telephoneNumber?: string
}

export const requestOnboarding = (data: INewPasswordData) =>
  fetch.post("/onboarding/", data)
export const requestResetPassword = (data: INewPasswordData) =>
  fetch.post("/reset-password/", data)
