import React from "react"

export const DeuIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M32.4864 28.4784V8.79028H40.5043V39.2079H7.49712V28.4784L12.0526 20.5882L16.0435 27.3029L20.0345 20.5882L24.0254 27.3029L28.0164 20.5882L32.4014 28.1835"
      stroke="black"
      strokeWidth="1.2"
    />
    <path
      d="M16.5781 31.2734H18.6914C19.168 31.2734 19.5312 31.3099 19.7812 31.3828C20.1172 31.4818 20.4049 31.6576 20.6445 31.9102C20.8841 32.1628 21.0664 32.4727 21.1914 32.8398C21.3164 33.2044 21.3789 33.6549 21.3789 34.1914C21.3789 34.6628 21.3203 35.069 21.2031 35.4102C21.0599 35.8268 20.8555 36.1641 20.5898 36.4219C20.3893 36.6172 20.1185 36.7695 19.7773 36.8789C19.5221 36.9596 19.181 37 18.7539 37H16.5781V31.2734ZM17.7344 32.2422V36.0352H18.5977C18.9206 36.0352 19.1536 36.0169 19.2969 35.9805C19.4844 35.9336 19.6393 35.8542 19.7617 35.7422C19.8867 35.6302 19.9883 35.4466 20.0664 35.1914C20.1445 34.9336 20.1836 34.5833 20.1836 34.1406C20.1836 33.6979 20.1445 33.3581 20.0664 33.1211C19.9883 32.8841 19.8789 32.6992 19.7383 32.5664C19.5977 32.4336 19.4193 32.3438 19.2031 32.2969C19.0417 32.2604 18.7253 32.2422 18.2539 32.2422H17.7344Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M22.3633 37V31.2734H26.6094V32.2422H23.5195V33.5117H26.3945V34.4766H23.5195V36.0352H26.7188V37H22.3633Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M27.6914 31.2734H28.8477V34.375C28.8477 34.8672 28.862 35.1862 28.8906 35.332C28.9401 35.5664 29.0573 35.7552 29.2422 35.8984C29.4297 36.0391 29.6849 36.1094 30.0078 36.1094C30.3359 36.1094 30.5833 36.043 30.75 35.9102C30.9167 35.7747 31.0169 35.6094 31.0508 35.4141C31.0846 35.2188 31.1016 34.8945 31.1016 34.4414V31.2734H32.2578V34.2812C32.2578 34.9688 32.2266 35.4544 32.1641 35.7383C32.1016 36.0221 31.9857 36.2617 31.8164 36.457C31.6497 36.6523 31.4258 36.8086 31.1445 36.9258C30.8633 37.0404 30.4961 37.0977 30.043 37.0977C29.4961 37.0977 29.0807 37.0352 28.7969 36.9102C28.5156 36.7826 28.293 36.6185 28.1289 36.418C27.9648 36.2148 27.8568 36.0026 27.8047 35.7812C27.7292 35.4531 27.6914 34.9688 27.6914 34.3281V31.2734Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
