import { getParser } from "bowser"

export const browser = getParser(window.navigator.userAgent)

export const isBrowserValid =
  browser.satisfies({
    chrome: ">=70",
    firefox: ">=60",
    safari: ">=9",
    edge: ">=79",
    electron: ">=18",
    chromium: ">=70",
  }) ||
  (browser.getBrowser().name === "Chromium" &&
    browser.getPlatform().type === "desktop")

export const isDesktop = browser.getPlatform().type === "desktop"
export const isTouchDevice =
  "ontouchstart" in window || navigator.maxTouchPoints > 0
