import { takeLatest, put, select, all } from "redux-saga/effects"

import * as serverDataActions from "library/common/actions/serverData"
import * as teethActions from "library/common/actions/teeth"
import * as serverDataSelectors from "library/common/selectors/serverData"
import * as entitiesSelectors from "library/common/selectors/entities"
import teethTypes from "library/common/types/teethTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { Detection, UserChange } from "../types/dataStructureTypes"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import {
  setDrawingAction,
  setIsErasing,
  setShowDrawingWarning,
  toggleActiveDrawingMode,
} from "../actions/drawing"
import { getDrawingAction, getIsDrawingModeActive } from "../selectors/drawing"
import { DrawingAction } from "../types/drawing"
import { getTeethAreShifting } from "../selectors/adjustments"
import { startExpanding } from "../actions/adjustments"

function* createToothSaga({
  payload: { id, removeRejectedAnnotations },
}: ReturnType<typeof teethActions.createTooth>) {
  try {
    const activeTooth = id
    if (!activeTooth) {
      return
    }

    const allRemovedTeeth: Tooth[] = yield select(
      serverDataSelectors.getAllRemovedTeeth
    )
    const foundInRemovedTeeth: boolean = allRemovedTeeth.some(
      (tooth) => tooth.toothName === activeTooth
    )

    if (foundInRemovedTeeth) {
      // Delete from removed teeth.
      yield put(serverDataActions.userDeleteDeletedTeeth(activeTooth))
      if (removeRejectedAnnotations) {
        // Also delete all rejected annotations
        const allDetections: Detection[] = yield select(
          entitiesSelectors.getDetections
        )
        const movedChange: UserChange[] = yield select(
          serverDataSelectors.getAllUserChanges
        )
        const movedDetectionIds = movedChange
          .filter((change) => change.action === "moved")
          .map((change) => change.annotationId)

        const annotationsForThatTooth = allDetections.filter(
          (detection) =>
            detection.toothName === activeTooth &&
            !movedDetectionIds.includes(detection.id)
        )
        yield all(
          annotationsForThatTooth.map((annotation) =>
            put(
              serverDataActions.deleteUserChange({
                id: annotation.id,
                deleteNonHSM: true,
              })
            )
          )
        )
      }
    } else {
      // Add to added teeth.
      const newTooth: Tooth = { toothName: activeTooth }
      yield put(serverDataActions.userAddAddedTeeth([newTooth]))
    }
  } catch (error) {
    console.error(error)
  }
}

function* deleteToothSaga({
  payload: { id, rejectAnnotations },
}: ReturnType<typeof teethActions.deleteTooth>) {
  try {
    const activeTooth = id
    if (!activeTooth) {
      return
    }
    const allAddedTeeth: Tooth[] = yield select(
      serverDataSelectors.getAllAddedTeeth
    )
    const foundInAddedTeeth: boolean = allAddedTeeth.some(
      (tooth) => tooth.toothName === activeTooth
    )
    const allAdditions: AnnotationOnTooth[] = yield select(
      serverDataSelectors.getAllAdditions
    )
    const additionsForTooth = allAdditions.filter(
      (addition) => addition.toothName === activeTooth
    )

    const isDrawingModeActive: boolean = yield select(getIsDrawingModeActive)

    if (isDrawingModeActive) yield put(toggleActiveDrawingMode())
    // Delete all additions for specific tooth
    yield all(
      additionsForTooth.map((addition) =>
        put(serverDataActions.deleteUserAddition(addition))
      )
    )

    // TODO: when you delete a tooth that has moved annotations they are not deleted
    // example: tooth 27 has an AI detected caries and is moved to 28. When you delete 28 the caries is not rejected and stays visible on the image
    if (foundInAddedTeeth) {
      // Delete from added teeth.
      yield put(serverDataActions.userDeleteAddedTeeth(activeTooth))
    } else {
      // Add to removed teeth.
      const newTooth: Tooth = { toothName: activeTooth }
      yield put(serverDataActions.userAddDeletedTeeth([newTooth]))
      if (rejectAnnotations) {
        // Reject all detections for that tooth.
        const allDetections: Detection[] = yield select(
          entitiesSelectors.getDetections
        )
        const movedChange: UserChange[] = yield select(
          serverDataSelectors.getAllUserChanges
        )
        const movedDetectionIds = movedChange
          .filter((change) => change.action === "moved")
          .map((change) => change.annotationId)
        const annotationsForThatTooth = allDetections.filter(
          (detection) =>
            detection.toothName === activeTooth &&
            !movedDetectionIds.includes(detection.id)
        )

        yield put(
          serverDataActions.addUserChanges(
            annotationsForThatTooth.map((annotation) => {
              const newChange: UserChange = {
                annotationId: annotation.id,
                action: "rejected",
              }

              return newChange
            })
          )
        )
      }
    }
  } catch (error) {
    console.error(error)
  }
}

function* setActiveToothSaga({
  payload: activeTooth,
}: ReturnType<typeof teethActions.setActiveTooth>) {
  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const teethAreShifting: boolean = yield select(getTeethAreShifting)

  // Set active moving tooth box
  yield put(setShowDrawingWarning(false))
  if (teethAreShifting && activeTooth) {
    yield put(startExpanding(activeTooth))
  }

  if (!activeTooth && drawingAction !== DrawingAction.annotate) {
    yield put(setDrawingAction(DrawingAction.select))
    yield put(setIsErasing(false))
  }
}

export default function* entitiesSaga() {
  yield takeLatest(teethTypes.CREATE_TOOTH, createToothSaga)
  yield takeLatest(teethTypes.DELETE_TOOTH, deleteToothSaga)
  yield takeLatest(teethTypes.SET_ACTIVE_TOOTH, setActiveToothSaga)
}
