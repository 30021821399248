export async function sha256(text: string) {
  // based on https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const data = new TextEncoder().encode(text)
  // tslint:disable-next-line
  const hash = await crypto.subtle.digest("SHA-256", data)

  // create a hex string from the sha bytes
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("")
}
