import fetch from "library/utilities/fetch"
import { Kind } from "library/common/types/serverDataTypes"
import { ContextQuery } from "library/common/types/userTypes"

export interface ReanalyzeRequest {
  id: string
  kind?: Kind
  rotate?: number
  isFlipped?: boolean
}

export interface ReportQuery extends ContextQuery {
  lang: string
  theme: string
  showVersion: boolean
  patientUuid: string | null
  toothmapV2?: boolean
  reports?: string
  detections?: string
  boneLossDetections?: string
  tz?: string
}

export interface ResultQuery extends ContextQuery {
  showHistory?: boolean
  masks?: boolean
  mode?: "combined" | "combined-hsm" | "default"
}

export const requestImageAnalysis = (id: string, params: ResultQuery) =>
  fetch.get(`/result/${id}/?client=frontend`, { params })

export const requestReanalyze = (
  data: ReanalyzeRequest,
  params: ContextQuery
) =>
  fetch.post(
    `/reanalyze/`,
    {
      ...data,
      ["frontend-upload"]: true,
    },
    { params }
  )

export const requestPdfReport = (id: string, params: ReportQuery) =>
  fetch.get(
    `/report-pdf/${id}.json?topMargin=8mm&bottomMargin=8mm&leftMargin=10mm&rightMargin=10mm&clipboard=1`,
    { params }
  )

export const requestSendPdfReport = (id: string, pdfReport: string) =>
  fetch.post(`/report-pdf/${id}/alfadocs/`, { pdf: pdfReport })

export const revertImage = (
  resultId: string,
  id: number,
  params: ContextQuery
) => fetch.post("/revert/", { result_id: resultId, id }, { params })
