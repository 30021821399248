import React from "react"

export const DrawIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6211 2.3789C21.0498 1.83313 20.2901 1.52857 19.5 1.52857C18.7099 1.52857 17.9502 1.83313 17.3789 2.3789L3.03752 16.719C2.54855 17.2053 2.16089 17.7838 1.89698 18.4209C1.63307 19.0581 1.49815 19.7412 1.50002 20.4309V22.5H3.56694C4.25669 22.502 4.93999 22.3671 5.57725 22.1032C6.21451 21.8393 6.79308 21.4516 7.27944 20.9625L21.6211 6.62143C21.8997 6.3429 22.1208 6.01221 22.2716 5.64824C22.4224 5.28426 22.5 4.89414 22.5 4.50016C22.5 4.10618 22.4224 3.71606 22.2716 3.35209C22.1208 2.98812 21.8997 2.65743 21.6211 2.3789ZM8.18252 13.695L10.304 15.8164L8.71292 17.4075L6.59147 15.2863L8.18252 13.695ZM6.21864 19.9017C5.87124 20.251 5.458 20.528 5.00283 20.7165C4.54766 20.905 4.05961 21.0014 3.56694 21H3.00002V20.4309C2.9987 19.9383 3.09508 19.4503 3.28361 18.9952C3.47213 18.5401 3.74904 18.1269 4.09832 17.7796L5.53082 16.3471L7.65227 18.4681L6.21864 19.9017ZM20.5606 5.56055L11.3646 14.7561L9.24317 12.6346L18.4394 3.43963C18.7251 3.16676 19.105 3.0145 19.5 3.0145C19.8951 3.0145 20.2749 3.16676 20.5606 3.43963C20.6999 3.57889 20.8104 3.74423 20.8858 3.9262C20.9612 4.10818 21 4.30323 21 4.5002C21 4.69718 20.9612 4.89223 20.8858 5.0742C20.8104 5.25618 20.6999 5.42129 20.5606 5.56055Z"
      fill="currentColor"
    />
    <path
      d="M4.87502 11.25C4.35587 11.2501 3.84834 11.0962 3.41664 10.8079C2.98493 10.5195 2.64845 10.1096 2.44973 9.63C2.25102 9.15039 2.19902 8.62261 2.30029 8.11343C2.40157 7.60426 2.65158 7.13655 3.01869 6.76948L5.64407 4.14448C5.85463 3.93332 5.97287 3.6473 5.97287 3.3491C5.97287 3.05091 5.85463 2.76488 5.64407 2.55373C5.42966 2.34924 5.14475 2.23516 4.84847 2.23516C4.55218 2.23516 4.26727 2.34924 4.05287 2.55373L2.56127 4.04518L1.50002 2.98498L2.99252 1.4936C3.49266 1.0163 4.15743 0.75 4.84877 0.75C5.54011 0.75 6.20488 1.0163 6.70502 1.4936C7.19641 1.98624 7.47238 2.65366 7.47237 3.34948C7.47238 4.0453 7.19641 4.71271 6.70502 5.20535L4.07927 7.82998C3.86871 8.04113 3.75047 8.32716 3.75047 8.62535C3.75047 8.92355 3.86871 9.20957 4.07927 9.42073C4.29366 9.62521 4.57856 9.73929 4.87483 9.73929C5.1711 9.73929 5.456 9.62521 5.67039 9.42073L8.68907 6.4025L9.75002 7.463L6.73097 10.4812C6.48784 10.7258 6.19861 10.9196 5.88003 11.0516C5.56145 11.1835 5.21985 11.251 4.87502 11.25Z"
      fill="currentColor"
    />
  </svg>
)
