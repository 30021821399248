import { RootState } from "core/store/configureStore"

export const getPatientListResult = (state: RootState) =>
  state.patients.patientListResult
export const getActivePatientResult = (state: RootState) =>
  state.patients.activePatientResult
export const getActivePatientUuid = (state: RootState) =>
  state.patients.activePatientResult.patient?.patientUuid

export const getFilterValues = (state: RootState) => state.patients.filterValues
export const getIsPatientMatchingMode = (state: RootState) =>
  state.patients.isPatientMatchingMode
export const getPatientMatch = (state: RootState) => state.patients.patientMatch
export const getPatientListSorting = (state: RootState) =>
  state.patients.patientListSorting
export const getActiveRadiographSet = (state: RootState) =>
  state.patients.activePatientResult.activeRadiographSet
export const getActivePatientListItem = (state: RootState) =>
  state.patients.activePatientResult.activePatientListItem
export const getIsAccordionOpen = (state: RootState) =>
  state.patients.activePatientResult.isAccordionOpen
