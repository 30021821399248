import { theme } from "@align-cpf/uikit-styles"
import { Theme } from "library/common/actions/user"
import { DEFAULT_THEME } from "library/utilities/constants"
import { createUseStyles } from "react-jss"

const isCarestream = DEFAULT_THEME === Theme.carestream

export default createUseStyles(
  {
    root: {
      color: theme.colors.textPrimary,
      ...(isCarestream && {
        "--backgroundInteractive": "#36b0c9",
        "--backgroundInteractiveHover": "#36b0c9cc",
        "--iconLink": "#36b0c9",
        "--textLink": "#36b0c9",
        "--blue30": "#36b0c9",
        "--borderFocus": "#36b0c9",
        "--borderInteractive": "#36b0c9",
      }),
    },
  },
  {
    name: "Baseline",
  }
)
