import { createAction } from "typesafe-actions"
import { WithHistoryTypes } from "../types/withHistoryTypes"

export const rememberState = createAction(WithHistoryTypes.REMEMBER_STATE)()
export const redo = createAction(WithHistoryTypes.REDO)()
export const undo = createAction(WithHistoryTypes.UNDO)()
export const reset = createAction(WithHistoryTypes.RESET)()
export const setInitialState = createAction(
  WithHistoryTypes.SET_INITIAL_STATE
)()
