import React from "react"

export const CloseFill = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.54152 3.26957C8.26823 2.11581 10.2983 1.5 12.375 1.5C15.1598 1.5 17.8305 2.60625 19.7996 4.57538C21.7688 6.54451 22.875 9.21523 22.875 12C22.875 14.0767 22.2592 16.1068 21.1054 17.8335C19.9517 19.5602 18.3118 20.906 16.3932 21.7007C14.4746 22.4955 12.3634 22.7034 10.3266 22.2982C8.28976 21.8931 6.41883 20.8931 4.95038 19.4246C3.48193 17.9562 2.4819 16.0852 2.07676 14.0484C1.67161 12.0116 1.87955 9.90045 2.67427 7.98182C3.46899 6.0632 4.8148 4.42332 6.54152 3.26957ZM15.5504 7.8474L12.3749 11.0229L9.19947 7.84747L8.2224 8.82454L11.3978 12L8.2224 15.1754L9.19947 16.1525L12.3749 12.977L15.5504 16.1525L16.5275 15.1755L13.352 12L16.5275 8.82447L15.5504 7.8474Z"
      fill="currentColor"
    />
  </svg>
)
