import React from "react"

export const DetectionsIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 4.5H4.5V7.5H7.5V4.5Z" fill="currentColor" />
    <path d="M10.5 7.5H7.5V10.5H10.5V7.5Z" fill="currentColor" />
    <path d="M13.5 4.5H10.5V7.5H13.5V4.5Z" fill="currentColor" />
    <path d="M19.5 4.5H16.5V7.5H19.5V4.5Z" fill="currentColor" />
    <path d="M7.5 10.5H4.5V13.5H7.5V10.5Z" fill="currentColor" />
    <path d="M13.5 10.5H10.5V13.5H13.5V10.5Z" fill="currentColor" />
    <path d="M19.5 10.5H16.5V13.5H19.5V10.5Z" fill="currentColor" />
    <path d="M7.5 16.5H4.5V19.5H7.5V16.5Z" fill="currentColor" />
    <path d="M13.5 16.5H10.5V19.5H13.5V16.5Z" fill="currentColor" />
    <path d="M19.5 16.5H16.5V19.5H19.5V16.5Z" fill="currentColor" />
    <path d="M16.5 7.5H13.5V10.5H16.5V7.5Z" fill="currentColor" />
    <path d="M10.5 13.5H7.5V16.5H10.5V13.5Z" fill="currentColor" />
    <path d="M16.5 13.5H13.5V16.5H16.5V13.5Z" fill="currentColor" />
  </svg>
)
