export interface Rectangle {
  x: number
  y: number
  width: number
  height: number
}

export interface RotatedRectangle extends Rectangle {
  rotate: number
}

export const boundingBox = ({
  x,
  y,
  width,
  height,
  rotate,
}: RotatedRectangle): Rectangle => {
  const cosT = Math.abs(Math.cos(rotate))
  const sinT = Math.abs(Math.sin(rotate))
  const newWidth = width * cosT + height * sinT
  const newHeight = width * sinT + height * cosT
  return {
    x: x + (width - newWidth) / 2,
    y: y + (height - newHeight) / 2,
    width: newWidth,
    height: newHeight,
  }
}
