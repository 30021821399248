import {
  call,
  put,
  take,
  fork,
  takeLatest,
  race,
  takeEvery,
  select,
} from "redux-saga/effects"
import { eventChannel, END, EventChannel } from "redux-saga"
import { WebSocketTypes } from "library/common/types/webSocketTypes"
import * as webSocketActions from "library/common/actions/webSocket"
import * as imageActions from "library/common/actions/image"
import { IAnalysisResult, updateImageDataSaga } from "./imageSaga"
import { requestImageAnalysis } from "library/services/imageApis"
import { filterTypes } from "../types/filterTypes"
import { UserTypes } from "../types/userTypes"
import { adjustmentTypes } from "../types/adjustmentTypes"
import { ServerDataTypes } from "../types/serverDataTypes"
import { imageTypes } from "../types/imageTypes"
import TeethTypes from "../types/teethTypes"
import { browser } from "library/utilities/browser"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import { REACT_APP_REVERT_VERSION } from "library/utilities/constants"

function createWebSocketConnection(endpoint: string) {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(endpoint)
    socket.onopen = () => resolve(socket)
    socket.onerror = (evt) => reject(evt)
  })
}

function createSocketChannel(socket: any) {
  return eventChannel((emit) => {
    socket.onmessage = (event: any) => emit(event.data)
    socket.onclose = () => emit(END)
    const unsubscribe = () => (socket.onmessage = null)

    return unsubscribe
  })
}

function* handleMessagesSaga(data: any, socket: WebSocket) {
  yield put(imageActions.imageProcessingComplete())
  socket.close()
}

function* listenForSocketMessages(imageId: string) {
  let socket: WebSocket | undefined
  let socketChannel: EventChannel<null> | undefined

  try {
    socket = yield call(() =>
      createWebSocketConnection(process.env.REACT_APP_API_URL_WS!)
    )
    socketChannel = yield call(createSocketChannel, socket)

    console.log("successfully connected to websocket")

    socket?.send(imageId)

    /*
    Check if status changed before websocket connected in order to set all the changed
    values (especially isProcessed and isOwner). Setting isProcessed will avoid infinite
    loading state
    */
    const params: ContextQuery = yield select(getContextQueryParams)
    const { data }: IAnalysisResult = yield call(
      requestImageAnalysis,
      imageId,
      { ...params, showHistory: REACT_APP_REVERT_VERSION }
    )
    if (data.status === "done") {
      yield call(updateImageDataSaga, data)
      return
    }

    while (true) {
      const payload: string = yield take(socketChannel as any)
      const parsedPayload = JSON.parse(payload)
      yield fork(handleMessagesSaga as any, parsedPayload, socket)
    }
  } catch (error) {
    console.error("error while connecting to websocket")
  } finally {
    socketChannel?.close()
    socket?.close()
  }
}

function* connectSaga({
  payload: imageId,
}: ReturnType<typeof webSocketActions.connect>) {
  // Listen for socket messages, but if we navigate away, cancel the process.
  yield race({
    task: call(listenForSocketMessages, imageId),
    cancel: take("@@router/LOCATION_CHANGE"),
  })
  console.log("successfully disconnected")
}

let UITrackingSocket: WebSocket | undefined

function* connectUIEventsSaga() {
  // Ensure UI tracking websocket is opened once
  if (!!UITrackingSocket || !process.env.REACT_APP_UI_EVENTS_WS) return

  try {
    UITrackingSocket = yield call(() =>
      createWebSocketConnection(
        `${process.env.REACT_APP_UI_EVENTS_WS}?token=${sessionStorage.getItem(
          "access_token"
        )}`
      )
    )

    console.log("successfully connected to UI events tracking websocket")
  } catch (error) {
    console.error("error while connecting to UI events tracking websocket")
  }
}

function* UIEventsSaga({
  payload: event,
  type,
}: ReturnType<typeof webSocketActions.UIEvents>) {
  const { width, height } = window.screen
  // For some actions / toggles without a payload, we pass a string as an action to prevent unwanted payload issues
  const toggles = [
    "@@USER/TOGGLE_CARIES_PRO",
    "@@USER/TOGGLE_BONELOSS_PRO",
    "@@FILTERS/TOGGLE_HSM",
    "@@SERVER_DATA/FLIP_IMAGE",
    "@@IMAGE/REANALYZE_IMAGE",
    "@@IMAGE/OPEN_PDF_REPORT",
  ]

  UITrackingSocket?.send(
    JSON.stringify({
      timestamp: Date.now() / 1e3, // timestamp should be sent as a float
      key: type,
      payload: toggles.includes(type)
        ? { action: "toggle / click action" }
        : { event },
      meta: {
        screenWidth: width,
        screenHeight: height,
        browser,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
  )
}

export default function* entitiesSaga() {
  yield takeLatest(WebSocketTypes.CONNECT, connectSaga)
  yield takeLatest(UserTypes.GET_USER_NAME, connectUIEventsSaga)
  yield takeEvery(
    [
      filterTypes.SET_FILTER_STATUS,
      adjustmentTypes.TOGGLE_ANNOTATION_ON_TOOTH,
      UserTypes.TOGGLE_CARIES_PRO,
      UserTypes.TOGGLE_BONELOSS_PRO,
      filterTypes.TOGGLE_HSM,
      ServerDataTypes.CHANGE_USER_ADDITION,
      ServerDataTypes.SET_TOOTH_BONELOSS,
      ServerDataTypes.SET_MOVED_TEETH,
      ServerDataTypes.ADJUST_FILTER,
      ServerDataTypes.FLIP_IMAGE,
      ServerDataTypes.ADD_USER_ADDITIONS,
      imageTypes.ROTATE_IMAGE,
      imageTypes.REANALYZE_IMAGE,
      imageTypes.OPEN_PDF_REPORT,
      imageTypes.SAVE_REPORT,
      imageTypes.COPY_REPORT_AS_IMAGE,
      imageTypes.COPY_REPORT_AS_TEXT,
      imageTypes.SET_SHOWN_RADIOGRAPH_ANNOTATIONS,
      TeethTypes.SET_ACTIVE_TOOTH,
    ],
    UIEventsSaga
  )
}
