import React from "react"

export const CloudUpload = () => (
  <svg
    width="29"
    height="27"
    viewBox="0 0 29 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49986 15.9996L10.9099 17.4096L13.4999 14.8296V26.9996H15.4999V14.8296L18.0899 17.4096L19.4999 15.9996L14.4999 10.9996L9.49986 15.9996Z"
      fill="currentColor"
    />
    <path
      d="M21.9999 19.9996H21.4999V17.9996H21.9999C23.1933 18.0473 24.3569 17.619 25.2346 16.8088C26.1122 15.9987 26.6321 14.873 26.6799 13.6796C26.7276 12.4861 26.2993 11.3225 25.4891 10.4449C24.679 9.5672 23.5533 9.04731 22.3599 8.99957H21.4999L21.3999 8.17957C21.178 6.49602 20.3517 4.95051 19.075 3.83095C17.7982 2.71139 16.158 2.09412 14.4599 2.09412C12.7618 2.09412 11.1215 2.71139 9.84477 3.83095C8.56799 4.95051 7.74172 6.49602 7.51986 8.17957L7.49986 8.99957H6.63986C5.44638 9.04731 4.32076 9.5672 3.5106 10.4449C2.70044 11.3225 2.27212 12.4861 2.31986 13.6796C2.3676 14.873 2.88749 15.9987 3.76516 16.8088C4.64283 17.619 5.80638 18.0473 6.99986 17.9996H7.49986V19.9996H6.99986C5.39626 19.9894 3.85302 19.3868 2.66684 18.3077C1.48066 17.2285 0.735276 15.7489 0.573989 14.1534C0.412703 12.558 0.846903 10.9591 1.79311 9.66442C2.73932 8.3697 4.13074 7.47045 5.69986 7.13957C6.13157 5.12609 7.24069 3.32154 8.84217 2.02704C10.4437 0.732535 12.4406 0.0263672 14.4999 0.0263672C16.5591 0.0263672 18.5561 0.732535 20.1575 2.02704C21.759 3.32154 22.8682 5.12609 23.2999 7.13957C24.869 7.47045 26.2604 8.3697 27.2066 9.66442C28.1528 10.9591 28.587 12.558 28.4257 14.1534C28.2644 15.7489 27.5191 17.2285 26.3329 18.3077C25.1467 19.3868 23.6035 19.9894 21.9999 19.9996Z"
      fill="currentColor"
    />
  </svg>
)
