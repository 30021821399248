import React from "react"

export const ManufacturerIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <path
      d="M6.91534 28.3799L12.0881 19.4204L16.0733 26.3231L20.0585 19.4204L24.0437 26.3231L28.0289 19.4204L32.0337 26.3572V8.18604H41.0846V39.8132H6.91582L6.91534 28.3799Z"
      fill="black"
      fillOpacity="0.93"
    />
  </svg>
)
