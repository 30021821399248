import { createAction as action } from "typesafe-actions"

import types from "../types/teethTypes"

export const setActiveTooth = action(types.SET_ACTIVE_TOOTH)<number | null>()
export const saveDetectedTeeth = action(types.SAVE_DETECTED_TEETH)<
  Array<{ toothName: number }>
>()
export const setInitialState = action(types.SET_INITIAL_STATE)()
export const createTooth = action(types.CREATE_TOOTH)<{
  id: number | null
  removeRejectedAnnotations: boolean
}>()
export const deleteTooth = action(types.DELETE_TOOTH)<{
  id: number | null
  rejectAnnotations: boolean
}>()
