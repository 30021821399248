import React from "react"

export const FlipIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 19.5H16.1667V17.8333H14.5V19.5ZM17.8333 9.49998H19.5V7.83331H17.8333V9.49998ZM4.5 6.16665V17.8333C4.5 18.75 5.25 19.5 6.16667 19.5H9.5V17.8333H6.16667V6.16665H9.5V4.49998H6.16667C5.25 4.49998 4.5 5.24998 4.5 6.16665ZM17.8333 4.49998V6.16665H19.5C19.5 5.24998 18.75 4.49998 17.8333 4.49998ZM11.1667 21.1666H12.8333V2.83331H11.1667V21.1666ZM17.8333 16.1666H19.5V14.5H17.8333V16.1666ZM14.5 6.16665H16.1667V4.49998H14.5V6.16665ZM17.8333 12.8333H19.5V11.1666H17.8333V12.8333ZM17.8333 19.5C18.75 19.5 19.5 18.75 19.5 17.8333H17.8333V19.5Z"
      fill="currentColor"
    />
  </svg>
)
