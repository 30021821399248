import { call, select, takeLatest, put } from "redux-saga/effects"

import { history } from "core/store/configureStore"
import { UploadTypes } from "library/common/types/uploadTypes"
import {
  IReportProblem,
  setReportProblemId,
  ActionWithData,
  uploadActionPreloaderImage,
  uploadActionSuccess,
  uploadActionError,
  uploadActionSetImageSizeError,
} from "../actions/upload"
import { setUserInfo } from "../actions/user"
import {
  requestSendReportProblem,
  requestUploadImage,
} from "library/services/uploadApi"
import { AxiosResponse } from "axios"
import { setPatientFileBreadcrumb } from "../actions/breadcrumbs"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"

export function* uploadImage(uploadData: {
  payload: ActionWithData
  type: string
}) {
  try {
    yield put(uploadActionPreloaderImage())
    const image = uploadData.payload.data
    const params: ContextQuery = yield select(getContextQueryParams)

    const data: AxiosResponse<{
      id: string
      uploadsRemaining?: number
    }> = yield call(
      requestUploadImage,
      {
        meta: JSON.stringify(uploadData.payload.meta),
        data: image,
      },
      params
    )

    const { id, uploadsRemaining } = data.data
    // update how many image uploads remain for this user
    yield put(setUserInfo({ uploadsRemaining }))
    yield put(uploadActionSuccess())
    // clear PatientFileBreadcrumb of previous image. The new image breadcrumbs are set when updating the image.
    yield put(setPatientFileBreadcrumb(""))
    yield history.push(`/dashboard/${id}`)
  } catch (error: any) {
    yield put(uploadActionError())
    if (error.response?.status === 400 && error.response.data) {
      const res = error.response.data
      let sizeError = ""
      if (/is too small/.test(res.imageHeight)) {
        sizeError = "small_height"
      } else if (/is too small/.test(res.imageWidth)) {
        sizeError = "small_width"
      } else if (/rotated incorrectly/.test(res.imageWidth)) {
        sizeError = "aspect_ratio"
      } else if (/is too large/.test(res.imageHeight)) {
        sizeError = "large_height"
      } else if (/is too large/.test(res.imageWidth)) {
        sizeError = "large_width"
      } else if (res.image) {
        sizeError = "general_read_error"
      }
      if (sizeError) {
        yield put(uploadActionSetImageSizeError(sizeError))

        return
      }
    }

    // unknown error: we cannot handle it
    throw error
  }
}

export function* sendReportProblem(problem: {
  payload: IReportProblem
  type: string
}) {
  try {
    const { data } = yield call(requestSendReportProblem, problem.payload)
    yield put(setReportProblemId(data.id))
  } catch (error) {
    console.log(error)
  }
}

export default function* watchUpload() {
  yield takeLatest(UploadTypes.UPLOAD_ACTION_WITH_DATA, uploadImage)
  yield takeLatest(
    UploadTypes.UPLOAD_ACTION_SEND_REPORT_PROBLEM,
    sendReportProblem
  )
}
