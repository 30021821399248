export const getMovingIndexPosition = (
  index: number,
  displayHorizontallyFlipped: boolean
) =>
  // Get the correct index list position when flipping the entire toothmap.
  //    It represents a teeth row in the upper or lower toothmap.
  // When we flip the entire toothmap we flip the indices
  // [15,14,13,12,11,10,9, 8, 7, 6, 5, 4, 3, 2, 1, 0] => After flipping the toothmap
  // and the current logic (to move teeth) uses list indexes to reference a tooth in the toothmap from 0 to 15
  //  with the correct order shown next:
  // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15] => Index list (for `start` and `to` variables)
  // [28,27,26,25,24,23,22,21,11,12,13,14,15,16,17,18] => Tooth number reference on the upper part of the toothmap.
  displayHorizontallyFlipped ? 15 - index : index
