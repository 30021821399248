import { createAction } from "typesafe-actions"
import { AnnotationOnTooth } from "../types/adjustmentTypes"

import {
  DrawingTypes,
  DrawingAction,
  DrawingLineThickness,
  DrawingAnnotation,
} from "../types/drawing"

export const setInitialState = createAction(DrawingTypes.SET_INITIAL_STATE)()

export const setDrawingAction = createAction(
  DrawingTypes.SET_DRAWING_ACTION
)<DrawingAction>()

export const toggleActiveDrawingMode = createAction(
  DrawingTypes.TOGGLE_ACTIVE_DRAWING_MODE
)()

export const setDrawingAnnotation = createAction(
  DrawingTypes.SET_DRAWING_ANNOTATION
)<DrawingAnnotation>()

export const setDrawing = createAction(DrawingTypes.SET_DRAWING)<boolean>()

export const setShowDrawingWarning = createAction(
  DrawingTypes.SET_SHOW_DRAWING_WARNING
)<boolean>()

export const setDrawingLineThickness = createAction(
  DrawingTypes.SET_DRAWING_LINE_THICKNESS
)<DrawingLineThickness>()

export const saveDrawingAnnotation = createAction(
  DrawingTypes.SAVE_DRAWING_ANNOTATION
)()

export const setActiveAddition = createAction(
  DrawingTypes.SET_ACTIVE_ADDITION
)<AnnotationOnTooth | null>()

export const setIsErasing = createAction(DrawingTypes.SET_IS_ERASING)<boolean>()

export const setActiveCariesProId = createAction(
  DrawingTypes.SET_ACTIVE_CARIES_PRO_ID
)<number | null>()

export const setActivePeriId = createAction(DrawingTypes.SET_ACTIVE_PERI_ID)<
  number | null
>()
