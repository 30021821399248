import React from "react"

export const MeasureIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6949 16.1425L7.98833 2.4216C7.85482 2.28795 7.69628 2.18192 7.52177 2.10958C7.34726 2.03723 7.16021 2 6.9713 2C6.78239 2 6.59533 2.03723 6.42082 2.10958C6.24631 2.18192 6.08777 2.28795 5.95426 2.4216L2.41801 5.96504C2.15028 6.23437 2 6.59871 2 6.97847C2 7.35824 2.15028 7.72258 2.41801 7.99191L16.1246 21.7128C16.2589 21.8461 16.4182 21.9515 16.5933 22.023C16.7685 22.0946 16.956 22.1308 17.1452 22.1297C17.5248 22.13 17.8891 21.9802 18.1586 21.7128L21.6949 18.1694C21.9626 17.9001 22.1129 17.5357 22.1129 17.156C22.1129 16.7762 21.9626 16.4119 21.6949 16.1425ZM17.1452 20.6922L3.43145 6.97847L6.9677 3.44222L9.69176 6.16629L8.07458 7.75472L9.08801 8.76816L10.7052 7.17972L13.6736 10.1482L12.0565 11.7653L13.0771 12.786L14.6943 11.1688L17.6627 14.1372L16.0455 15.7544L17.0877 16.7678L18.7049 15.1507L20.6815 17.156L17.1452 20.6922Z"
      fill="currentColor"
    />
  </svg>
)
