import React from "react"

export const ECREPIcon = () => (
  <svg
    width="48"
    height="16"
    viewBox="0 0 48 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.51801 12.0439V5.50728H11.3648V6.61308H7.83782V8.0622H11.1195V9.16353H7.83782V10.9426H11.4896V12.0439H6.51801ZM16.7912 9.64063L18.0709 10.0464C17.8747 10.7598 17.5477 11.2904 17.0899 11.6382C16.6351 11.983 16.057 12.1554 15.3554 12.1554C14.4874 12.1554 13.774 11.8596 13.2152 11.2681C12.6563 10.6736 12.3769 9.86209 12.3769 8.83358C12.3769 7.74562 12.6578 6.90141 13.2196 6.30096C13.7815 5.69753 14.5201 5.39581 15.4357 5.39581C16.2353 5.39581 16.8848 5.63213 17.3842 6.10477C17.6815 6.38419 17.9044 6.78548 18.053 7.30865L16.7466 7.62077C16.6693 7.2819 16.5073 7.01437 16.2606 6.81818C16.0168 6.62199 15.7196 6.5239 15.3688 6.5239C14.8843 6.5239 14.4904 6.69779 14.1872 7.04558C13.887 7.39337 13.7369 7.95667 13.7369 8.73548C13.7369 9.56186 13.8855 10.1504 14.1828 10.5012C14.48 10.8519 14.8664 11.0273 15.3421 11.0273C15.6928 11.0273 15.9945 10.9159 16.2472 10.6929C16.4999 10.47 16.6812 10.1192 16.7912 9.64063Z"
      fill="currentColor"
    />
    <path
      d="M27.1274 12.0439V5.50728H29.9052C30.6038 5.50728 31.1106 5.56673 31.4257 5.68564C31.7438 5.80157 31.9979 6.00965 32.1882 6.30987C32.3784 6.6101 32.4735 6.95343 32.4735 7.33987C32.4735 7.83034 32.3294 8.23609 32.041 8.55713C31.7527 8.87519 31.3217 9.07584 30.748 9.15907C31.0333 9.32554 31.2682 9.50835 31.4525 9.70751C31.6397 9.90667 31.8909 10.2604 32.206 10.7687L33.0041 12.0439H31.4257L30.4715 10.6216C30.1326 10.1133 29.9008 9.79372 29.7759 9.66292C29.6511 9.52916 29.5188 9.4385 29.3791 9.39093C29.2394 9.3404 29.0179 9.31513 28.7147 9.31513H28.4472V12.0439H27.1274ZM28.4472 8.27176H29.4237C30.0568 8.27176 30.4522 8.24501 30.6097 8.19151C30.7673 8.138 30.8906 8.04585 30.9798 7.91506C31.069 7.78426 31.1136 7.62077 31.1136 7.42458C31.1136 7.20462 31.0541 7.02775 30.9352 6.89398C30.8193 6.75724 30.6543 6.67104 30.4403 6.63537C30.3333 6.62051 30.0123 6.61308 29.4772 6.61308H28.4472V8.27176ZM33.722 12.0439V5.50728H38.5688V6.61308H35.0418V8.0622H38.3235V9.16353H35.0418V10.9426H38.6936V12.0439H33.722ZM39.8128 12.0439V5.50728H41.9307C42.7333 5.50728 43.2565 5.53998 43.5003 5.60538C43.8748 5.70347 44.1884 5.9175 44.4411 6.24745C44.6937 6.57443 44.8201 6.99802 44.8201 7.51822C44.8201 7.91952 44.7472 8.2569 44.6016 8.53038C44.4559 8.80385 44.2701 9.01936 44.0442 9.17691C43.8213 9.33148 43.5939 9.43404 43.362 9.48457C43.0469 9.54699 42.5906 9.57821 41.9932 9.57821H41.1326V12.0439H39.8128ZM41.1326 6.61308V8.46795H41.8549C42.3751 8.46795 42.7229 8.43377 42.8983 8.3654C43.0737 8.29703 43.2104 8.19002 43.3085 8.04436C43.4096 7.89871 43.4601 7.72927 43.4601 7.53606C43.4601 7.29825 43.3903 7.10206 43.2506 6.94749C43.1108 6.79292 42.934 6.69631 42.72 6.65766C42.5624 6.62794 42.2458 6.61308 41.7702 6.61308H41.1326Z"
      fill="currentColor"
    />
    <rect
      x="0.35122"
      y="0.35122"
      width="23.6488"
      height="15.2195"
      stroke="currentColor"
      strokeWidth="0.702439"
    />
    <rect
      x="24"
      y="0.35122"
      width="23.6488"
      height="15.2195"
      stroke="currentColor"
      strokeWidth="0.702439"
    />
  </svg>
)
