import React from "react"

export const ResearchUseOnlyIcon = () => (
  <svg
    width="60"
    height="48"
    viewBox="0 0 60 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="48" fill="white" />
    <path
      d="M39.2516 33.8328V34.2102C39.2516 34.8154 39.1695 35.3586 39.0055 35.8398C38.8414 36.3211 38.6099 36.7313 38.3109 37.0703C38.012 37.4057 37.6547 37.6628 37.2391 37.8414C36.8271 38.0201 36.3695 38.1094 35.8664 38.1094C35.3669 38.1094 34.9094 38.0201 34.4937 37.8414C34.0818 37.6628 33.7245 37.4057 33.4219 37.0703C33.1193 36.7313 32.8841 36.3211 32.7164 35.8398C32.5523 35.3586 32.4703 34.8154 32.4703 34.2102V33.8328C32.4703 33.224 32.5523 32.6807 32.7164 32.2031C32.8805 31.7219 33.112 31.3117 33.4109 30.9727C33.7135 30.6336 34.0708 30.3747 34.4828 30.1961C34.8984 30.0174 35.356 29.9281 35.8555 29.9281C36.3586 29.9281 36.8161 30.0174 37.2281 30.1961C37.6437 30.3747 38.001 30.6336 38.3 30.9727C38.6026 31.3117 38.8359 31.7219 39 32.2031C39.1677 32.6807 39.2516 33.224 39.2516 33.8328ZM37.5945 34.2102V33.8219C37.5945 33.399 37.5562 33.0271 37.4797 32.7063C37.4031 32.3854 37.2901 32.1156 37.1406 31.8969C36.9911 31.6781 36.8089 31.5141 36.5937 31.4047C36.3786 31.2917 36.1326 31.2352 35.8555 31.2352C35.5784 31.2352 35.3323 31.2917 35.1172 31.4047C34.9057 31.5141 34.7253 31.6781 34.5758 31.8969C34.4299 32.1156 34.3187 32.3854 34.2422 32.7063C34.1656 33.0271 34.1273 33.399 34.1273 33.8219V34.2102C34.1273 34.6294 34.1656 35.0013 34.2422 35.3258C34.3187 35.6466 34.4318 35.9182 34.5812 36.1406C34.7307 36.3594 34.913 36.5253 35.1281 36.6383C35.3432 36.7513 35.5893 36.8078 35.8664 36.8078C36.1435 36.8078 36.3896 36.7513 36.6047 36.6383C36.8198 36.5253 37.0003 36.3594 37.1461 36.1406C37.2919 35.9182 37.4031 35.6466 37.4797 35.3258C37.5562 35.0013 37.5945 34.6294 37.5945 34.2102ZM46.9133 30.0375V38H45.2727L42.0734 32.6625V38H40.4328V30.0375H42.0734L45.2781 35.3805V30.0375H46.9133ZM53.4594 36.7203V38H49.4508V36.7203H53.4594ZM49.9812 30.0375V38H48.3406V30.0375H49.9812ZM54.0281 30.0375L55.6906 33.6688L57.3531 30.0375H59.1414L56.5219 35.1016V38H54.8594V35.1016L52.2344 30.0375H54.0281Z"
      fill="black"
      fillOpacity="0.93"
    />
    <path
      d="M4.0352 38V8.2976H11.7312C15.5584 8.2976 18.3872 9.0048 20.2176 10.4192C22.048 11.8336 22.9632 13.9552 22.9632 16.784C22.9632 18.6976 22.4363 20.3477 21.3824 21.7344C20.3285 23.0933 18.8448 24.0917 16.9312 24.7296L19.3024 28.5984L21.632 24.8544H25.168L20.9248 31.0944L25.2512 38H21.4656L18.7616 33.5488L15.8496 38H12.3552L17.1392 30.9696L13.8944 25.6864H7.7792V38H4.0352ZM12.0224 22.5664C14.352 22.5664 16.1131 22.0949 17.3056 21.152C18.4981 20.2091 19.0944 18.8085 19.0944 16.9504C19.0944 15.5637 18.8032 14.4821 18.2208 13.7056C17.6384 12.9013 16.7787 12.3328 15.6416 12C14.5045 11.6395 13.1179 11.4592 11.4816 11.4592H7.7792V22.5664H12.0224Z"
      fill="black"
    />
  </svg>
)
