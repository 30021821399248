import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/breadcrumbs"

type BreadcrumbState = Readonly<{
  patientFileBreadcrumb: string
  imageIDBreadcrumb: string
  reportBreadcrumb: string
}>

const initialState: BreadcrumbState = {
  patientFileBreadcrumb: "",
  imageIDBreadcrumb: "",
  reportBreadcrumb: "",
}

type BreadcrumbActions = ActionType<typeof actions>

export default (
  state = initialState,
  action: BreadcrumbActions
): BreadcrumbState => {
  switch (action.type) {
    case getType(actions.setInitialBreadcrumbState): {
      return { ...initialState }
    }
    case getType(actions.setPatientFileBreadcrumb):
      return {
        ...state,
        patientFileBreadcrumb: action.payload,
      }
    case getType(actions.setImageIDBreadcrumb):
      return {
        ...state,
        imageIDBreadcrumb: action.payload,
        // reportBreadcrumb can't exists when we have no imageIDBreadcrumb
        ...(action.payload === "" && {
          reportBreadcrumb: "",
        }),
      }
    case getType(actions.setReportBreadcrumb):
      return {
        ...state,
        reportBreadcrumb: action.payload,
      }

    default:
      return state
  }
}
