import React from "react"
import { CssBaseline } from "@align-cpf/uikit"
import Storage from "library/utilities/localStorage"

type Theme = React.ComponentProps<typeof CssBaseline>["theme"]

const BaselineContext = React.createContext<
  { theme: Theme; setTheme: (t: Theme) => void } | undefined
>(undefined)

const themes = ["light", "dark"] as const

const LS = {
  getTheme: () => {
    const theme = Storage.getItem("theme")
    if (themes.includes(theme)) {
      return theme as Theme
    }

    return "dark"
  },

  setTheme: (theme: Theme) => {
    Storage.setItem("theme", theme)
  },
}

export const ThemeProvider: React.FC<{
  children: React.ReactNode
  theme?: Theme
}> = ({ children, theme: defaultTheme = LS.getTheme() }) => {
  const [theme, setTheme] = React.useState<Theme>(defaultTheme)

  const handleTheme = (t: Theme) => {
    LS.setTheme(t)
    setTheme(t)
  }

  return (
    <BaselineContext.Provider value={{ theme, setTheme: handleTheme }}>
      {children}
    </BaselineContext.Provider>
  )
}

export const useTheme = () => {
  const context = React.useContext(BaselineContext)

  if (!context) {
    throw new Error("Cannot be used without ThemeProvider")
  }

  return context
}
