import { createSelector } from "reselect"
import { RootState } from "core/store/configureStore"

export const getRoutePathName = (state: RootState) =>
  state.router.location.pathname

export const getRouteImageId = createSelector(
  [getRoutePathName],
  (path: string) => {
    // find a UUID at the end of the path (https://stackoverflow.com/a/14166194)
    const splittedPath =
      /\/(?<id>[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})\/?$/.exec(
        path
      )?.groups?.id || ""
    if (!splittedPath.length) return

    return splittedPath
  }
)

export const getIsIntegrated = createSelector(
  getRoutePathName,
  (pathName: string) => pathName.indexOf("integrated") >= 0
)
