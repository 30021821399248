import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/saving"
import { ResultStatus } from "../types/dataStructureTypes"

type SavingState = Readonly<{
  dataIsChanged: boolean
  savingResultStatus: ResultStatus
}>

const initialState: SavingState = {
  dataIsChanged: false,
  savingResultStatus: ResultStatus.none,
}

type SavingActions = ActionType<typeof actions>

export default (state = initialState, action: SavingActions): SavingState => {
  switch (action.type) {
    case getType(actions.requestSendChangesComplete):
      return action.payload.success ? { ...state, dataIsChanged: false } : state
    case getType(actions.setSavingResultStatus): {
      return {
        ...state,
        savingResultStatus: action.payload,
      }
    }
    case getType(actions.setDataIsChanged):
      return { ...state, dataIsChanged: action.payload }
    default:
      return state
  }
}
