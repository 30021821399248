const notifyWebkit = (id: string, status: string) => {
  const data = {
    action: "reloadResult",
    result: {
      id,
      status,
    },
  }
  const win = window as any
  if (!win.webkit) {
    return console.log("Cannot post message to webkit:", JSON.stringify(data))
  }

  return win.webkit.messageHandlers.webToNative.postMessage(data)
}

export const notifyWebkitSave = (id: string) => {
  notifyWebkit(id, "Ok")
}

export const notifyWebkitCancel = (id: string) => {
  notifyWebkit(id, "Abort")
}
