import React from "react"

export const EraseIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7501 16.8751H4.3751V18.1251H18.7501V16.8751Z"
      fill="currentColor"
    />
    <path
      d="M17.1126 6.56881L12.1564 1.61881C12.0403 1.50258 11.9024 1.41039 11.7507 1.34748C11.5989 1.28458 11.4362 1.2522 11.272 1.2522C11.1077 1.2522 10.9451 1.28458 10.7933 1.34748C10.6416 1.41039 10.5037 1.50258 10.3876 1.61881L1.6376 10.3688C1.52138 10.4849 1.42919 10.6228 1.36628 10.7745C1.30337 10.9263 1.271 11.0889 1.271 11.2532C1.271 11.4174 1.30337 11.5801 1.36628 11.7319C1.42919 11.8836 1.52138 12.0215 1.6376 12.1376L4.45635 15.0001H10.4501L17.1126 8.33756C17.2288 8.22147 17.321 8.0836 17.3839 7.93186C17.4468 7.78011 17.4792 7.61745 17.4792 7.45318C17.4792 7.28891 17.4468 7.12625 17.3839 6.97451C17.321 6.82276 17.2288 6.6849 17.1126 6.56881ZM9.93135 13.7501H5.0001L2.5001 11.2501L6.44385 7.30631L11.4001 12.2563L9.93135 13.7501ZM12.2814 11.4001L7.33135 6.44381L11.2501 2.50006L16.2501 7.45631L12.2814 11.4001Z"
      fill="currentColor"
    />
  </svg>
)
