import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(
  {
    "@keyframes rotate": {
      from: { transform: "rotate(0turn)" },
      to: { transform: "rotate(1turn)" },
    },

    path: {
      animation: "$rotate infinite linear 1s",
      transformOrigin: "50% 50%",
    },
  },
  {
    name: "Processing",
  }
)

export const Processing = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={useStyles().path}
      d="M5.13876 2.72464C6.57769 1.76318 8.26942 1.25 10 1.25C12.3206 1.25 14.5462 2.17187 16.1872 3.81282C17.8281 5.45376 18.75 7.67936 18.75 10H17.5C17.5 8.51664 17.0601 7.06659 16.236 5.83322C15.4119 4.59985 14.2406 3.63856 12.8701 3.0709C11.4997 2.50325 9.99168 2.35472 8.53683 2.64411C7.08197 2.9335 5.7456 3.64781 4.6967 4.6967C3.64781 5.74559 2.9335 7.08197 2.64411 8.53682C2.35473 9.99168 2.50325 11.4997 3.07091 12.8701C3.63856 14.2406 4.59986 15.4119 5.83323 16.236C7.0666 17.0601 8.51664 17.5 10 17.5C11.5748 17.5 13.0985 17.0047 14.3629 16.1005L15.1308 17.0878C14.5805 17.4862 13.9827 17.8212 13.3485 18.0839C11.7496 18.7462 9.9903 18.9195 8.29296 18.5819C6.59563 18.2442 5.03653 17.4109 3.81282 16.1872C2.58911 14.9635 1.75575 13.4044 1.41813 11.707C1.08051 10.0097 1.25379 8.25037 1.91606 6.65152C2.57832 5.05267 3.69983 3.6861 5.13876 2.72464Z"
      fill="currentColor"
    />
  </svg>
)
