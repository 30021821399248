import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"

export const getActiveTooth = (state: RootState) => state.teeth.activeTooth
export const getTeethTemplateLeft2Right = (state: RootState) =>
  state.teeth.teethTemplate
export const getTeethTemplateRight2Left = (state: RootState) =>
  state.teeth.teethTemplateReversed

export const getDisplayHorizontallyFlippedDuplicatedForTeeth = (
  state: RootState
) => !!state.serverData.present.imageMeta.displayHorizontallyFlipped

/**
 * Use this to get the teeth template considering both flipping flags:
 *   `isImageHorizontallyFlipped` and `displayHorizontallyFlipped`
 */
export const getTeethTemplate = createSelector(
  [
    getDisplayHorizontallyFlippedDuplicatedForTeeth,
    getTeethTemplateLeft2Right,
    getTeethTemplateRight2Left,
  ],
  (displayHorizontallyFlipped, teethTemplateL2R, teethTemplateR2L) =>
    displayHorizontallyFlipped ? teethTemplateR2L : teethTemplateL2R
)
