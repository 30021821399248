import { store } from "core/store/configureStore"
import { openModal } from "library/common/actions/modal"
import { ActionLogin, CoachMarkRecord } from "library/common/actions/user"
import { Modals } from "library/common/reducers/modalsReducer"
import { ContextQuery } from "library/common/types/userTypes"
import { getRenewUrl } from "library/utilities/token"
import authorizedFetch, { authFetch } from "../utilities/fetch"

const show403ErrorModal = (e: any) => {
  if (getRenewUrl() && e.response?.status === 403) {
    store.dispatch(openModal(Modals.NO_SUBSCRIPTION_MODAL))
  }

  return Promise.reject(e)
}

export const requestLogin = (data: ActionLogin) => {
  const action = data.isSSO
    ? authFetch.post("/sso-login/", {
        sso_provider: data.username,
        token: data.password,
        redirect_uri: data.redirect_uri,
        direct_login_username: data.directLoginUsername,
        ...(data.isRefreshToken && {
          grant_type: "refresh_token",
        }),
      })
    : authFetch.post("/login/", {
        username: data.username,
        password: data.password,
      })
  return action.catch(show403ErrorModal)
}
export const requestGetUserName = (params: ContextQuery) =>
  authorizedFetch.get(`/user/me/`, { params }).catch(show403ErrorModal)
export const requestSetKnownHandlers = (data: { knownHandlers: string[] }) =>
  authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)
export const requestSetWhatsNew = (data: { whatsNew: string }) =>
  authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)
export const requestSetAlfaDocsApiKey = (alfaDocsApiKey: string) =>
  authorizedFetch.post(`/user/me/`, { alfaDocsApiKey })

export const requestSetLastCoachMarks = (data: {
  lastCoachMarks: CoachMarkRecord
}) => authorizedFetch.post(`/user/me/`, data).catch(show403ErrorModal)

export const requestGenerateToken = (params: ContextQuery) =>
  authorizedFetch.post(`/upload-token/`, {}, { params })
