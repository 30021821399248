import { createUseStyles } from "react-jss"

export default createUseStyles(
  {
    root: {
      "& > svg": {
        display: "block",
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "Icon",
  }
)
