import React from "react"

export const Information = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4090_10721)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5ZM10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75Z"
        fill="currentColor"
      />
      <path
        d="M10.6836 8.9248V14.2552H9.30499V8.9248H10.6836Z"
        fill="currentColor"
      />
      <circle cx="9.99428" cy="6.68282" r="1.04317" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_4090_10721">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
)
