import React from "react"

export const CEIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" />
    <g clipPath="url(#clip0_2795_150111)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 38.9249C19.0016 38.975 18.5009 39 18 39C14.0218 39 10.2064 37.4197 7.3934 34.6066C4.58035 31.7936 3 27.9783 3 24C3 20.0218 4.58035 16.2065 7.3934 13.3934C10.2064 10.5804 14.0218 9 18 9C18.5009 9 19.0016 9.02509 19.5 9.07519V13.5752C19.0024 13.5144 18.5012 13.4892 18 13.5C15.2152 13.5 12.5445 14.6063 10.5754 16.5754C8.60625 18.5445 7.5 21.2152 7.5 24C7.5 26.7848 8.60625 29.4555 10.5754 31.4247C12.5445 33.3938 15.2152 34.5 18 34.5C18.5012 34.5108 19.0024 34.4857 19.5 34.4249V38.9249Z"
        fill="black"
        fillOpacity="0.93"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45 38.9249C44.5016 38.975 44.0009 39 43.5 39C39.5218 39 35.7064 37.4197 32.8934 34.6066C30.0804 31.7936 28.5 27.9783 28.5 24C28.5 20.0218 30.0804 16.2065 32.8934 13.3934C35.7064 10.5804 39.5218 9 43.5 9C44.0009 9 44.5016 9.02509 45 9.07519V13.5752C44.5024 13.5144 44.0012 13.4892 43.5 13.5C41.106 13.4998 38.7837 14.3177 36.9183 15.8182C35.0528 17.3187 33.7561 19.4116 33.243 21.75H42V26.25H33.243C33.7561 28.5885 35.0528 30.6814 36.9183 32.1818C38.7837 33.6823 41.106 34.5002 43.5 34.5C44.0012 34.5108 44.5024 34.4857 45 34.4249V38.9249Z"
        fill="black"
        fillOpacity="0.93"
      />
    </g>
    <defs>
      <clipPath id="clip0_2795_150111">
        <rect width="42" height="30" fill="white" transform="translate(3 9)" />
      </clipPath>
    </defs>
  </svg>
)
