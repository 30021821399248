import React from "react"

export const CloseOutline = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.375 1.5C10.2983 1.5 8.26823 2.11581 6.54152 3.26957C4.8148 4.42332 3.46899 6.0632 2.67427 7.98182C1.87955 9.90045 1.67161 12.0116 2.07676 14.0484C2.4819 16.0852 3.48193 17.9562 4.95038 19.4246C6.41883 20.8931 8.28976 21.8931 10.3266 22.2982C12.3634 22.7034 14.4746 22.4955 16.3932 21.7007C18.3118 20.906 19.9517 19.5602 21.1054 17.8335C22.2592 16.1068 22.875 14.0767 22.875 12C22.875 9.21523 21.7688 6.54451 19.7996 4.57538C17.8305 2.60625 15.1598 1.5 12.375 1.5ZM12.375 21C10.595 21 8.85492 20.4722 7.37487 19.4832C5.89483 18.4943 4.74128 17.0887 4.06009 15.4442C3.3789 13.7996 3.20067 11.99 3.54794 10.2442C3.8952 8.49836 4.75237 6.89471 6.01104 5.63604C7.26972 4.37737 8.87336 3.5202 10.6192 3.17293C12.365 2.82567 14.1746 3.0039 15.8192 3.68508C17.4637 4.36627 18.8693 5.51983 19.8582 6.99987C20.8472 8.47991 21.375 10.22 21.375 12C21.375 14.3869 20.4268 16.6761 18.739 18.364C17.0511 20.0518 14.762 21 12.375 21Z"
      fill="currentColor"
    />
    <path
      d="M11.3978 12L8.22242 15.1754L9.19948 16.1525L12.3749 12.9771L15.5504 16.1525L16.5275 15.1755L13.352 12L16.5275 8.82448L15.5504 7.84741L12.3749 11.0229L9.19947 7.84748L8.2224 8.82455L11.3978 12Z"
      fill="currentColor"
    />
  </svg>
)
