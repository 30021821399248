import React from "react"

export const RotationIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 22.5H12C11.6023 22.4995 11.2211 22.3414 10.9398 22.0602C10.6586 21.7789 10.5005 21.3977 10.5 21V12C10.5005 11.6023 10.6586 11.2211 10.9398 10.9398C11.2211 10.6586 11.6023 10.5005 12 10.5H21C21.3977 10.5005 21.7789 10.6586 22.0602 10.9398C22.3414 11.2211 22.4995 11.6023 22.5 12V21C22.4995 21.3977 22.3414 21.7789 22.0602 22.0602C21.7789 22.3414 21.3977 22.4995 21 22.5ZM12 12V21H21.0009L21 12H12Z"
      fill="currentColor"
    />
    <path
      d="M11.25 1.5L10.1925 2.5575L12.1275 4.5H8.25C6.85809 4.50155 5.52363 5.05517 4.5394 6.0394C3.55517 7.02363 3.00155 8.35809 3 9.75V13.5H4.5V9.75C4.50113 8.75579 4.89658 7.80262 5.5996 7.0996C6.30262 6.39658 7.25579 6.00113 8.25 6H12.1275L10.1925 7.9425L11.25 9L15 5.25L11.25 1.5Z"
      fill="currentColor"
    />
  </svg>
)
