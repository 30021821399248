import React from "react"

export const UdiIcon = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ background: "white" }}
  >
    <g clipPath="url(#clip0_2104_121577)">
      <path d="M0 16V0H16" stroke="#999999" strokeWidth="3" />
      <path d="M200 16V0H184" stroke="#999999" strokeWidth="3" />
      <path d="M0 184V200H16" stroke="#999999" strokeWidth="3" />
      <path d="M200 184V200H184" stroke="#999999" strokeWidth="3" />
      <rect
        x="18.7586"
        y="49.7586"
        width="163.621"
        height="103.483"
        stroke="black"
        strokeWidth="5.51724"
      />
      <path
        d="M40.1888 74.3763H51.0536V103.52C51.0536 108.145 51.1882 111.143 51.4574 112.513C51.9223 114.715 53.0235 116.489 54.7608 117.835C56.5227 119.157 58.9208 119.817 61.9551 119.817C65.0383 119.817 67.363 119.193 68.9291 117.945C70.4952 116.673 71.4373 115.119 71.7554 113.284C72.0735 111.449 72.2325 108.402 72.2325 104.144V74.3763H83.0973V102.639C83.0973 109.099 82.8037 113.663 82.2164 116.33C81.6291 118.998 80.5402 121.249 78.9496 123.084C77.3835 124.919 75.2791 126.388 72.6363 127.489C69.9935 128.566 66.5432 129.104 62.2854 129.104C57.1467 129.104 53.2437 128.517 50.5764 127.342C47.9337 126.143 45.8415 124.601 44.2998 122.717C42.7582 120.808 41.7427 118.814 41.2533 116.734C40.5437 113.651 40.1888 109.099 40.1888 103.08V74.3763Z"
        fill="black"
      />
      <path
        d="M94.5494 74.3763H114.407C118.885 74.3763 122.299 74.7189 124.648 75.404C127.804 76.3339 130.508 77.9856 132.76 80.3592C135.011 82.7328 136.724 85.6448 137.898 89.0951C139.073 92.5209 139.66 96.7543 139.66 101.795C139.66 106.224 139.11 110.042 138.008 113.247C136.663 117.162 134.742 120.331 132.246 122.754C130.361 124.589 127.817 126.021 124.611 127.048C122.213 127.807 119.007 128.186 114.994 128.186H94.5494V74.3763ZM105.414 83.4792V119.12H113.526C116.56 119.12 118.75 118.949 120.096 118.606C121.858 118.166 123.314 117.419 124.464 116.367C125.639 115.315 126.593 113.59 127.327 111.192C128.061 108.769 128.428 105.478 128.428 101.318C128.428 97.158 128.061 93.9647 127.327 91.7379C126.593 89.5111 125.565 87.7737 124.244 86.5257C122.923 85.2777 121.246 84.4335 119.215 83.9931C117.698 83.6505 114.725 83.4792 110.296 83.4792H105.414Z"
        fill="black"
      />
      <path d="M148.58 128.186V74.3763H159.444V128.186H148.58Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_2104_121577">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
