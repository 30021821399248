import React from "react"

export const SunIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.71094 1H7.71094V3.5H8.71094V1Z" fill="currentColor" />
    <path
      d="M12.8071 2.69678L11.0542 4.4497L11.7613 5.15673L13.5142 3.40381L12.8071 2.69678Z"
      fill="currentColor"
    />
    <path d="M15.2109 7.5H12.7109V8.5H15.2109V7.5Z" fill="currentColor" />
    <path
      d="M11.7613 10.8433L11.0542 11.5503L12.8071 13.3032L13.5142 12.5962L11.7613 10.8433Z"
      fill="currentColor"
    />
    <path d="M8.71094 12.5H7.71094V15H8.71094V12.5Z" fill="currentColor" />
    <path
      d="M4.66064 10.8433L2.90772 12.5962L3.61476 13.3032L5.36767 11.5503L4.66064 10.8433Z"
      fill="currentColor"
    />
    <path d="M3.71094 7.5H1.21094V8.5H3.71094V7.5Z" fill="currentColor" />
    <path
      d="M3.61476 2.69677L2.90772 3.40381L4.66064 5.15673L5.36768 4.44969L3.61476 2.69677Z"
      fill="currentColor"
    />
    <path
      d="M8.21094 5C7.61759 5 7.03757 5.17595 6.54423 5.50559C6.05088 5.83524 5.66636 6.30377 5.4393 6.85195C5.21224 7.40013 5.15283 8.00333 5.26858 8.58527C5.38434 9.16721 5.67006 9.70176 6.08962 10.1213C6.50917 10.5409 7.04372 10.8266 7.62567 10.9424C8.20761 11.0581 8.81081 10.9987 9.35899 10.7716C9.90717 10.5446 10.3757 10.1601 10.7053 9.66671C11.035 9.17336 11.2109 8.59334 11.2109 8C11.2109 7.20435 10.8949 6.44129 10.3323 5.87868C9.76965 5.31607 9.00659 5 8.21094 5Z"
      fill="currentColor"
    />
  </svg>
)
